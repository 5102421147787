import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOperatorVM } from 'src/app/modules/location/model/operator-indicators.model';

@Component({
  selector: 'milestone-operators-card',
  templateUrl: './operators-card.component.html',
  styleUrls: ['./operators-card.component.scss']
})
export class OperatorsCardComponent {

  @Input() operator: IOperatorVM | undefined;
  @Output() cardClicked = new EventEmitter<IOperatorVM>();
  @Output() actionTriggered = new EventEmitter<IOperatorVM>(); // Event emitter to notify parent component
  @Output() viewMap = new EventEmitter<IOperatorVM>(); // Event emitter to notify parent component


  triggerAction(): void {
    this.actionTriggered.emit(this.operator); // Emit the event when the button is clicked
  }

  triggerMap(): void {
    this.viewMap.emit(this.operator);
  }

  onCardClick() : void {
    this.cardClicked.emit(this.operator);
  }

}
