import { Component } from '@angular/core';

@Component({
  selector: 'milestone-status-cod',
  templateUrl: './status-cod.component.html',
  styleUrls: ['./status-cod.component.scss']
})
export class StatusCodComponent {

}
