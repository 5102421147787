<ng-template matExpansionPanelContent>

    <hr style="margin-top: -7px" />
    <div [ngClass]="trackingOrders.length >= 8 ? 'scroll' : ''">
      <div class="history-tl-container">
        <ul class="tl" style="width: 100%">
          <li
            *ngFor="let track of trackingOrders"
            class="tl-item {{
            (track.statusOrder.statusOrders.includes('ISSUE') || track.statusOrder.statusOrders.includes('REFUSED'))
                ? 'Incident'
                : track.statusOrder.statusOrders
            }}"
          >
            <div class="bloc_tracking">
              <div class="item-title">
                <p 
                  milestoneTranslate="order.status_order.{{track.statusOrder.statusOrders}}"
                  [translateValues]="{user: getUserName(track.user), operator: track.operator?? '-'}"
                  >
                  {{track.statusOrder.statusOrders}}
                  <br/>
                </p>
              <span *ngIf="track.statusOrder.statusOrders?.includes('ISSUE') || track.statusOrder.statusOrders?.includes('REFUSED')">
                {{track.description}}
              </span>
              </div>
              <div class="timestamp">
                <span
                  >{{ track.createdDate * 1000 | date : "dd-MM-YYYY" }}
                  {{ track.createdDate * 1000 | date : "HH:mm:ss" }}</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
