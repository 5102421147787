import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { NotificationService } from './notification.service';

@NgModule({
  imports: [
    // ToastrModule.forRoot({
    //     timeOut: 10000,
    //     progressBar: true,
    //     closeButton: true,
    //   }),
    ToastrModule.forRoot({
      maxOpened: 10, // Limit the number of notifications displayed simultaneously
      preventDuplicates: true, // Prevent duplicate notifications
      positionClass: 'toast-top-right', // Position of the notifications
      toastClass: 'ngx-toastr custom-toast', // Custom CSS class for the notification
      progressBar: true, // Display a progress bar
      newestOnTop: true, // Newest notifications appear on top
      enableHtml: true, // Enable HTML in notifications
      closeButton: true, // Show a close button on the notifications
      timeOut: 10000, // Time duration the toast should be shown
    }),
  ],
  exports: [ToastrModule],
  providers: [NotificationService],
})
export class NotificationModule {}
