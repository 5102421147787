<div class="container-module-select">
  <div class="header-container">
    <input
      type="text"
      name="search"
      placeholder="Search functions..."
      (input)="onGlobalSearch($event)"
    />
    <div class="header-text">{{ profileName }}</div>
  </div>

  <div *ngFor="let item of modulesFunctions" class="module-container">
    <div class="module-header" (click)="toggleModule(item.module)">
      <div *ngIf="item.expanded">
        <iconify-icon icon="iwwa:arrow-down"></iconify-icon>
      </div>
      <div *ngIf="!item.expanded">
        <iconify-icon icon="iwwa:arrow-up"></iconify-icon>
      </div>
      <div class="module-name">
        {{ item.module.modules }}
      </div>
      <div class="module-count">
        {{ item.functions.length }}
      </div>
      <div class="module-checkbox">
        <input
          class="form-check-input"
          type="checkbox"
          [(ngModel)]="item.module.selected"
          (change)="toggleModuleSelection(item)"
        />
      </div>
    </div>

    <div *ngIf="item.expanded" class="functions-container">
      <div *ngFor="let func of item.functions" class="function-item">
        <div class="function-name">
          {{ func.functions }}
        </div>
        <div class="function-info">
          <button (click)="onOpenDescription(func)">
            <iconify-icon icon="material-symbols:info"></iconify-icon>
          </button>
        </div>
        <div class="module-checkbox">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="func.selected"
            (change)="toggleFunctionSelection(func, item)"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<milestone-modal
  [title]="modalTitle"
  [icon]="'assets/icontclose.svg'"
  (closeModal)="handleClose()"
  *ngIf="isModalDescriptionOpen"
>
  <ng-container>
    <ng-template [ngTemplateOutlet]="customTemplate"></ng-template>
  </ng-container>
</milestone-modal>

<ng-template #customTemplate>
  <div class="custom-content-table">
    <p>{{ modalContent }}</p>
  </div>
</ng-template>
