import { Component } from '@angular/core';

@Component({
  selector: 'milestone-type-collect',
  templateUrl: './type-collect.component.html',
  styleUrls: ['./type-collect.component.scss']
})
export class TypeCollectComponent {

}
