import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApplicationConfigService } from './../../../core/config/application-config.service';
import { createRequestOption } from './../../../core/request/request-util';
import { Pagination } from './../../../core/request/request.model';
import { RequestSearch } from './../../../core/specification/requestSearch';
import { AppConstant } from './../../../shared/app-constant';
import { IParamsVM, ParamsPagenationsVM } from '../models/params-vm.model';

@Injectable({
  providedIn: 'root',
})
export class ParamsService {
  private apiUrl = '/params';

  constructor(
    private http: HttpClient,
    private appConfigService: ApplicationConfigService
  ) {}

  // Create or update a Params
  createOrUpdateParams(params: IParamsVM): Observable<void> {
    return this.http.post<void>(
      this.appConfigService.getEndpointFor(`${this.apiUrl}/create-or-update`),
      params,
      AppConstant.httpOptions
    );
  }

  // Delete a Params by ID
  deleteParams(id: number): Observable<void> {
    return this.http.delete<void>(
      this.appConfigService.getEndpointFor(`${this.apiUrl}/delete/${id}`),
      AppConstant.httpOptions
    );
  }

  // Get all Params
  getAllParams(): Observable<IParamsVM[]> {
    return this.http.get<IParamsVM[]>(
      this.appConfigService.getEndpointFor(`${this.apiUrl}/all`),
      AppConstant.httpOptions
    );
  }

  // Get Params by code
  getParamsByCode(code: string): Observable<IParamsVM> {
    return this.http.get<IParamsVM>(
      this.appConfigService.getEndpointFor(`${this.apiUrl}/by-code/${code}`),
      AppConstant.httpOptions
    );
  }

  // Get Params by query with pagination
  query( pagination: Pagination, filters?: RequestSearch | null): Observable<ParamsPagenationsVM> {
    const paramsQuery = createRequestOption(pagination);
    return this.http.post<ParamsPagenationsVM>(
      this.appConfigService.getEndpointFor(`${this.apiUrl}/query`),
      filters,
      { headers: AppConstant.httpOptions.headers, params: paramsQuery }
    );
  }
}
