

        <div class="bloc_tracking">                   
       <div class="item-title">
          <p>{{track.statusOrder.statusOrders}}</p>
       </div>
       <div class="timestamp">
        <span>{{ track.createdDate * 1000 | date : "dd-MM-YYYY" }}
        {{ track.createdDate * 1000 | date : "HH:mm:ss" }}</span>
       </div>
    </div>

