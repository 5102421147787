import {
  Component,

  OnInit,

} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'milestone-pickup-confirm-affectation',
  templateUrl: './pickup-confirm-affectation.component.html',
  styleUrls: ['./pickup-confirm-affectation.component.scss']
})
export class PickupConfirmAffectationComponent implements OnInit{

  constructor(protected activeModal: NgbActiveModal, private router:Router, private location: Location) {}

  ngOnInit(): void { 
  }

  close():void{
   
    this.activeModal.close();
    // this.router.navigate(['/pickup-operations']);
  }
}
