import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastrSrv: ToastrService) {}

  showMessageSuccess(message: string): void {
    this.toastrSrv.success('SUCCESS: ' + message, 'Success');
  }
  showMessageError(message: string): void {
    this.toastrSrv.error('ERROR: ' + message, 'error');
  }

  showMessageWarning(message: string): void {
    this.toastrSrv.warning('WARNING: ' + message, 'warning');
  }
  showMessageInfo(message: string): void {
    this.toastrSrv.info('INFO: ' + message, 'info');
  }

  // Request permission to display notifications
  requestPermission(): void {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.log('Notification permission denied.');
        }
      });
    }
  }

  // Show a desktop notification
  showNotification(title: string, options?: NotificationOptions): void {
    if ('Notification' in window && Notification.permission === 'granted') {
      // Remove unsupported actions for non-persistent notifications
      const { ...validOptions } = options ?? {}; // Remove actions if present
      const notification = new Notification(title, validOptions);

      // Optionally, add event listeners to the notification
      notification.onclick = () => {
        console.log('Notification clicked');
      };
      notification.onclose = () => {
        console.log('Notification closed');
      };
    }
  }
}
