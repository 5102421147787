<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="alert alert-danger" *ngIf="createCityError" data-cy="loginError">
    <strong>Create City!</strong> {{ errorCity }}
  </div>

  <div class="form-group">
    <label class="label" for="idCity">City Id</label>
    <input
      id="idCity"
      formControlName="idCity"
      type="text"
      class="form-control"
      placeholder="City Id"
    />
  </div>

  <div class="form-group">
    <label class="label" for="cityName">City name</label>
    <input
      id="cityName"
      formControlName="cityName"
      type="text"
      class="form-control"
      placeholder="Casablanca"
    />
  </div>

  <div class="form-group">
    <label class="label" for="agency">Agency</label>
    <milestone-dropdown-select
      [items]="agencies"
      displayField="agencyName"
      placeholder="Select Agency"
      (itemSelected)="selectAgency($event)"
    >
    </milestone-dropdown-select>
  </div>

  <div class="form-group">
    <label class="label" for="zone">Zone</label>
    <milestone-dropdown-select
      [items]="zones"
      displayField="zone"
      placeholder="Select Zone"
      (itemSelected)="selectZone($event)"
    >
    </milestone-dropdown-select>
  </div>

  <div class="form-check">
    <input
      id="confirmation"
      formControlName="confirmation"
      type="checkbox"
      class="form-check-input"
    />
    <label for="confirmation" class="form-check-label">Yes, confirmation</label>
  </div>

  <div class="form-group mt-3">
    <button type="submit" class="btn-action-modal">
      <ng-container *ngIf="!loading; else spinner">
        <span>Create City</span>
      </ng-container>
      <ng-template #spinner>
        <div class="spinner-border text-light" role="status"></div>
      </ng-template>
    </button>
  </div>
</form>
