import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { AgencyPagenationsVM, AgencyVM } from '../models/agency-vm.model';
import { CreateAgencyVM } from '../models/create-agency-vm.model';
@Injectable({
  providedIn: 'root',
})
export class AgenciesService {
  constructor(
    private http: HttpClient,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createAgency(agency: CreateAgencyVM): Observable<void> {
    const data = {
      idAgency: agency.idAgency,
      agencyName: agency.agencyName,
      description: agency.description,
      addressAgency: agency.addressAgency,
      position: agency.position,
    };
    return this.http
      .post<void>(
        this.applicationConfigService.getEndpointFor('/admin/agencies'),
        data,
        AppConstant.httpOptions
      );
  }

  query(): Observable<AgencyPagenationsVM> {
    return this.http
      .post<AgencyPagenationsVM>(
        this.applicationConfigService.getEndpointFor('/agencies/query'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }

  getAllAgencies(): Observable<AgencyVM[]> {
    return this.http
      .get<AgencyVM[]>(
        this.applicationConfigService.getEndpointFor('/agencies/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
