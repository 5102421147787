import { Component, Input } from '@angular/core';
import { ITrackingOrderVM } from 'src/app/modules/tracking-order/models/tracking-order-vm.model';

@Component({
  selector: 'milestone-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent {
  @Input() track!: ITrackingOrderVM;

  
}
