import { Component } from '@angular/core';

@Component({
  selector: 'milestone-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  isModalOpen = false;



  columns = [
    { header: 'ID', field: 'id' },
    { header: 'User', field: 'user' },
    { header: 'Profile', field: 'profile' },
    { header: 'Phone', field: 'phone' },
    { header: 'Email', field: 'email' },
    { header: 'Status', field: 'status' },
    { header: 'Agency', field: 'agency' },
    { header: 'Secteur', field: 'secteur' },
  ];

  users = [
    {
      id: '530',
      user: 'Hedi ZAZ',
      profile: 'Profile 1',
      phone: '+212 600 0000 10',
      email: 'hedi@gmail.com',
      status: 'Active',
      agency: 'Agency 1',
      secteur: 'Secteur 1',
    },
    {
      id: '330',
      user: 'Hamza alaoui',
      profile: 'Profile 2',
      phone: '+212 608 8140 03',
      email: 'hedi@gmail.com',
      status: 'Active',
      agency: 'Agency 2',
      secteur: 'Secteur 2',
    },
  ];

  dropdownFilters = [
    {
      label: 'Agency',
      options: ['Agency 1', 'Agency 2'],
      selected: 'Agency 1',
    },
    { label: 'User Type', options: ['Type 1', 'Type 2'], selected: 'Type 1' },
    {
      label: 'Oussama Type',
      options: ['Oussama 1', 'Type 2'],
      selected: 'Oussama 1',
    },
    {
      label: 'Oussama Type',
      options: ['Oussama 1', 'Type 2'],
      selected: 'Oussama 1',
    },
  ];

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted', formData);
    this.isModalOpen = false;
    // this.onClickCreate();
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }



  
}
