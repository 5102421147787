import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { IZoneVM } from './models/zone-vm.model';
import { ZoneService } from './services/zones.service';

@Component({
  selector: 'milestone-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss'],
})
export class ZonesComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'Zone', field: 'zone' },
    { header: 'Description', field: 'description' },
    { header: 'Created by', field: 'createdBy' },
  ];

  zones: IZoneVM[] = [];

  dropdownFilters = [];

  constructor(
    private zoneService: ZoneService,
    private router: Router,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadZones();
  }

  loadZones(): void {
    this.zoneService.getZones().subscribe(
      (response) => {
        this.zones = response.content;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      () => {
        this.notification.showMessageError('Error loading zones');
      }
    );
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }
}
