// /api
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { CreateShelvingVM } from '../models/create-shelving-vm.model';
import { IShelvingVM, ShelvingPagenationsVM } from '../models/shelving-vm.model';

@Injectable({
  providedIn: 'root',
})
export class ShelvingService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createShelving(shelving: CreateShelvingVM): Observable<void> {
    const data = {
      idShelving: shelving.idShelving,
      agency: { idAgency: shelving.agency },
      capacity: shelving.capacity,
      location: shelving.location,
    };
    return this.http
      .post<IShelvingVM>(
        this.applicationConfigService.getEndpointFor('/admin/shelving'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) => console.log('response', response)));
  }

  getShelvings(): Observable<ShelvingPagenationsVM> {
    return this.http
      .get<ShelvingPagenationsVM>(
        this.applicationConfigService.getEndpointFor('/shelving/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
