// /api
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { CreateZoneVM } from '../models/create-zone-vm.model';
import { IZoneVM, ZonePagenationsVM } from '../models/zone-vm.model';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createZone(zone: CreateZoneVM): Observable<void> {
    return this.http
      .post<IZoneVM>(
        this.applicationConfigService.getEndpointFor('/admin/zones'),
        zone,
        AppConstant.httpOptions
      )
      .pipe(map((response) => console.log('response', response)));
  }

  getZones(): Observable<ZonePagenationsVM> {
    return this.http
      .get<ZonePagenationsVM>(
        this.applicationConfigService.getEndpointFor('/zones/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
