
 
            <div class="modal-header"><img src="assets/images/Success.png" alt=""></div>
            <div class="modal-body" style="text-align:center">
                <img src="assets/images/png/success2.png" alt="">
                <h6 style="margin:30px 0">L'ordre a bien été affecté</h6>
            </div>
            <div class="modal-footer">
                <button id="bt-modal-closed" (click)="close()" class="btn btn-default" aria-hidden="true" >Continuer</button> 
            </div>


