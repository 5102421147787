import { Component } from '@angular/core';

@Component({
  selector: 'milestone-type-delivery',
  templateUrl: './type-delivery.component.html',
  styleUrls: ['./type-delivery.component.scss']
})
export class TypeDeliveryComponent {

}
