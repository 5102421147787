import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolygonService {
  polygonSource = new Subject<string>();
  polygon$ = this.polygonSource.asObservable();

  sendPolygon(geom: string): void {
    this.polygonSource.next(geom);
  }
}
