<form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="form-group">
      <label for="address"><strong>Rechercher une adresse avec Google Maps</strong></label>

      <input class="input"
      type="text"
      [ngModel]="autocompleteInput"
      formControlName="address"
      class="form-control"      
      (input)="onInputChange($event)"
      #addresstext style="padding: 12px 20px; border: 1px solid #ccc; width: 400px"
      >

      <div *ngIf="addressControl?.invalid && addressControl?.touched">
        <small style="color:red">Veuillez sélectionner une adresse valide</small>
      </div>
  
      <div *ngIf="submitted && addressForm.get('address')?.errors?.['required']; else pres">
        <small style="color:red">Ce champe est obligatoire</small>
      </div>
      <ng-template #pres>

      <!--<div *ngIf="submitted && pres_adresse">
        <div style="color:red">Veuillez choisir une adresse proche</div>
      </div>-->
    </ng-template>
  
    </div>
    <div class="form-group">
      <label for="quartier"><strong>Préciser le quartier</strong></label>
      <input
        id="quartier"
        formControlName="quartier"
        type="text"
        class="form-control"
        placeholder="Veuillez entrer le quartier ici"
        value="{{quartierr}}"
      />
      <div *ngIf="submitted && addressForm.get('quartier')?.errors?.['required']">
        <small style="color:red">Ce champe est obligatoire</small>
      </div>
    </div>
    <div class="form-group">
      <label for="description"><strong>Ajouter des informations complémentaires</strong></label>
      <textarea
        id="description"
        formControlName="description"
        class="form-control"
        placeholder="Vous pouvez entrer ici des informations complémentaires" rows="4" cols="50"
      ></textarea>
    </div>
  </div>
  
  <div class="form-group mt-3">
    <button type="submit" class="btn-action-modal" >
      <ng-container *ngIf="!loading; else spinner">
        <span>Modifier l'adresse</span>
      </ng-container>
      <ng-template #spinner>
        <div class="spinner-border text-light" role="status"></div>
      </ng-template>
    </button>
  </div>
</form>
