<div>
  <div class="d-flex justify-content-between align-items-center">
    <h2 class="text-lg fw-bold">Modules</h2>
  </div>
  <div class="mt-8">
    <milestone-table
      [columns]="columns"
      [data]="permissionModules"
      (rowClick)="handleRowAction($event)"
      [itemsPerPage]="pageInit"
      [dropdownFilters]="dropdownFilters"
      [customComponent]="customTemplate"
      [actionButtonText]="'Create Module'"
      [exportButtonText]="'Export Modules'"
      [poupdata]="poupdata"
      (actionClick)="onClickCreate()"
      (exportClick)="onExportClick()"
    ></milestone-table>
  </div>

  <milestone-modal
    [title]="'Create Module'"
    [icon]="'assets/icontclose.svg'"
    (closeModal)="handleClose()"
    *ngIf="isModalOpen"
  >
    <milestone-create-permission-module-form
      (formSubmit)="handleFormSubmit($event)"
      (formCancel)="handleClose()"
    ></milestone-create-permission-module-form>
  </milestone-modal>

  <ng-template #customTemplate>
    <!-- Custom component content goes here -->
    <div class="custom-content-table">
      <p>Custom content here for Modules</p>
    </div>
  </ng-template>

  <ng-template #poupdata let-item>
    <button
      class="d-flex justify-content-center align-items-center rounded-circle"
      type="button"
      data-bs-toggle="dropdown"
    >
      <iconify-icon icon="system-uicons:menu-vertical"></iconify-icon>
    </button>
    <div class="dropdown-menu to-top dropdown-menu-sm">
      <ul class="to-top-list">
        <li>
          <a
            class="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
            (click)="onEdit(item)"
          >
            <iconify-icon icon="mage:edit-fill"></iconify-icon>
            Edit
          </a>
        </li>
        <li>
          <a
            class="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
            (click)="onDelete(item)"
          >
            <iconify-icon icon="material-symbols:delete-sharp"></iconify-icon>
            Delete
          </a>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
