<div id="map-container">
  <div id="map" style="height: 500px; width: 100%"></div>

  <div class="control-panel">
    <!-- Color Slider -->
    <input
      type="color"
      (input)="changePolygonColor($event)"
      title="Choose polygon color"
    />

    <!-- Add Point Button -->
    <button type="button" (click)="addPointFromControl()">
      <iconify-icon icon="ic:outline-add"></iconify-icon>
    </button>

    <!-- Remove Point Button -->
    <button type="button" (click)="removePoint()">
      <iconify-icon icon="material-symbols:remove"></iconify-icon>
    </button>

    <!-- Clear Polygon Button -->
    <button type="button" (click)="clearPolygon()">
      <iconify-icon icon="material-symbols:delete-sharp"></iconify-icon>
    </button>

    <!-- Save Button -->
    <button type="button" (click)="savePolygon()">
      <iconify-icon icon="material-symbols:save"></iconify-icon>
    </button>
  </div>
</div>
