import { Component } from '@angular/core';

@Component({
  selector: 'milestone-operator-list',
  templateUrl: './operator-list.component.html',
  styleUrls: ['./operator-list.component.scss']
})
export class OperatorListComponent {

}
