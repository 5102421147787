export class FunctionFiltters{

    getPickupInfoNotAssigned(): string[] {
        return [
        "INFO_NEW_ORDER",
        "PICKUP_ISSUE_REFUSED_BY_AGENT" ,
        "PICKUP_INFO_UNASSIGNED"
        ];
    }

    getPickupInfoAssigned(): string[] {
        return ["PICKUP_INFO_ASSIGNED"];
    }

    getPickedUp(): string[] {
        return ["PICKUP_INFO_PICKED_UP"];
    }

    getPickupIssue(): string[] {
        return [
            "PICKUP_ISSUE_UPDATE_REFUSED_CONTACT",
            "PICKUP_ISSUE_UPDATE_REFUSED_ADDRESS",
            "PICKUP_ISSUE_PARCEL_UNAVAILABLE",
            "PICKUP_ISSUE_PARCEL_OUTSIZE",
            "PICKUP_ISSUE_SENDER_UNAVAILABLE",
            "PICKUP_ISSUE_SENDER_UNREACHABLE",
            "PICKUP_ISSUE_PARCEL_WITHOUT_LABEL",
            "PICKUP_ISSUE_CAR_ACCIDENT",
            "PICKUP_ISSUE_BROKEN_CAR",
            "PICKUP_ISSUE_WRONG_ADDRESS",
            "PICKUP_ISSUE_CANT_PARK",
            "PICKUP_ISSUE_CANT_ACCESS",
            "POINTING_ISSUE_MISSING_AT_POINTING",
            "POINTING_ISSUE_DAMAGED_AT_POINTING"
        ];
    }

    getPickupCanceled(): string[] {
        return [
            "PICKUP_INFO_CANCELED_BY_SENDER",
            "PICKUP_INFO_CANCELED_BY_SERVICE",
            "PICKUP_INFO_CANCELLATION_CONFIRMED"
        ];
    }

    getPickupRescheduled(): string[] {
        return [
            "PICKUP_INFO_RESCHEDULED_BY_SENDER",
            "PICKUP_INFO_RESCHEDULED_BY_SERVICE"
        ];
    }

}
