import { Injectable } from '@angular/core';
import {
  ACCESS_TOKEN,
  AUTHENTICATED,
  REFRESH_TOKEN,
  USER_KEY,
} from 'src/app/data-constant/key-constants';
import { JwtToken } from '../../login/model/jwt-token';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { UserVM } from './../../modules/user/models/user-vm.model';

@Injectable({
  providedIn: 'root',
})
export class ClientDataStorageService {
  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService
  ) {}

  signOut(): void {
    window.sessionStorage.clear();
    this.localStorageService.clear();
    this.sessionStorageService.clear();
  }

  public saveToken(jwtToken: JwtToken, rememberMe: boolean): void {
    this.sessionStorageService.clear(ACCESS_TOKEN);
    this.localStorageService.clear(ACCESS_TOKEN);
    // new code 
    this.localStorageService.clear(REFRESH_TOKEN);

    if (jwtToken.accessToken) {
     // if (rememberMe) {
     // this.sessionStorageService.store(ACCESS_TOKEN, jwtToken.accessToken);
        this.localStorageService.store(ACCESS_TOKEN, jwtToken.accessToken);
     // } else {
        // this.sessionStorageService.store(ACCESS_TOKEN, jwtToken.accessToken);
     // }
    }
    if (jwtToken.refreshToken) {
     // if (rememberMe) {
      // this.sessionStorageService.store(REFRESH_TOKEN, jwtToken.refreshToken);
        this.localStorageService.store(REFRESH_TOKEN, jwtToken.refreshToken);
    //  } else {
        // this.sessionStorageService.store(REFRESH_TOKEN, jwtToken.refreshToken);
      // }
    }
  }

  public getToken(): JwtToken | null {
    const jwtToken = {
      accessToken: this.sessionStorageService.retrieve(ACCESS_TOKEN)?? this.localStorageService.retrieve(ACCESS_TOKEN)?? null,
      refreshToken: this.sessionStorageService.retrieve(REFRESH_TOKEN)?? this.localStorageService.retrieve(REFRESH_TOKEN)?? null,
    };
    return jwtToken;
  }

  public saveUser(user: UserVM): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    // new code
    this.localStorageService.store(USER_KEY, JSON.stringify(user));

  }

  public getUser(): any | null {
    const user = window.sessionStorage.getItem(USER_KEY) ?? null;
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  public isAutenticated(): boolean {
   // const strValue = window.sessionStorage.getItem(AUTHENTICATED);
   // new code
   const strValue = this.localStorageService.retrieve(AUTHENTICATED);
    const isAuth = strValue ? strValue.toLowerCase() === 'true' : false;
    return (
      isAuth && this.getToken()?.accessToken != null && this.getToken()?.refreshToken != null
      // && this.getUser() != null
    );
  }

  public setAuthenticated(authenticated: string): void {
   // window.sessionStorage.setItem(AUTHENTICATED, authenticated);
   // new code
   this.localStorageService.store(AUTHENTICATED, authenticated);
  }
}
