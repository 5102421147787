<div class="select-filter-container">
  <div class="flex">
    <div class="title-select">Select columns to display</div>

    <button class="btn btn-success" (click)="applySelection()">Apply</button>
  </div>

  <mat-selection-list [(ngModel)]="selectedColumns">
    <mat-list-option
      *ngFor="let column of columns"
      [value]="column"
      style="--mdc-checkbox-selected-icon-color: #1e3a8a"
    >
      {{ column.header }}
    </mat-list-option>
  </mat-selection-list>
</div>
