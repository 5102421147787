<div class="row mt-2">
  <div class="col-md-9">
    <div class="card" style="border-left: 9px solid #385888; padding-top: 15px">
      <div class="card-body">
        <div class="bloc_ordres">
          <div class="item_separ">
            <p class="title_details">Références:</p>
            <span class="value_details" style="color: #14027a">
              <span *ngIf="shipment && shipment.idOrder && !shipment.idMMBin">
                <strong>Réf. ordre :</strong>&nbsp;&nbsp;
                <a  [routerLink]="['/pickup-operation-details', shipment.idOrder]">
                  {{shipment.idOrder}}
                </a>
              </span>
              <span *ngIf="shipment && shipment.idMMBin" class="mr-rem-1">
                <strong>Réf. sac de transport :</strong>&nbsp;&nbsp;
                <a [routerLink]="['/pickup-operation-details', shipment.idOrder]">
                  {{shipment.idMMBin}}
                </a>
              </span>
  
              <br />
              <strong>Réf. expédition :</strong>&nbsp;&nbsp;{{idShipment | number : "1.0-0"}}
              <br />
              <strong>Réf code client :</strong>&nbsp;&nbsp;{{shipment?.idParcelClient}}
            </span>
          </div>
          <div class="item_separ">
            <p class="title_details">Expéditeur:</p>
            <span class="value_details">
              {{ shipment?.firstnameSender !== null ? shipment?.firstnameSender: "-" }}
              {{ shipment?.lastnameSender !== null ? shipment?.lastnameSender: "-" }}
              <br/>
              <a href="tel:+{{ shipment?.gsmSender?.replace('00212', '0') }}"> 
                {{ shipment?.gsmSender?.replace("00212", "0")}}
              </a>
            </span>
          </div>

          <div class="item_separ">
            <div class="content_bloc">
              <div>
                <img src="assets/images/png/delivery.png" alt="" />
              </div>
              <div>
                <p class="title_details"> Opérateur: </p>
                <span
                    class="value_details"
                    *ngIf="trackingShipments.length && trackingShipments[0]?.order && trackingShipments[0].order?.user !== null; else notUser"
                    >
                    {{ trackingShipments[0].order.user?.firstname }}
                    {{ trackingShipments[0].order.user?.lastname }}
                    <br />
                    <a href="tel:+{{trackingShipments[0].order.user?.mobile?.replace('00212','0')}}">
                      {{trackingShipments[0].order.user?.mobile?.replace("00212","0")}}
                    </a>
                  </span>
                  <ng-template #notUser> - </ng-template>
              </div>
            </div>
          </div>

          <div class="item_separ">
            <span *ngIf="shipment?.addressCollect !== null; else adresse">
              <div class="content_bloc">
                <div>
                  <img src="assets/images/png/home-icone.png" alt="" />
                </div>
                <div>
                  <p class="title_details">Départ:</p>
                  <span class="value_details">
                    {{ shipment?.cityDeparture?.cityName }}, <br />
                    {{ shipment?.addressCollect }}
                    </span>
                </div>
              </div>
            </span>
            <ng-template #adresse>
              <div class="content_bloc">
                <div style="text-align: center">
                  <img src="assets/images/svg/smartlocker.svg" alt="" />
                </div>
                <div>
                  <p class="title_details">Départ:</p>
                  <span class="value_details"
                    >{{ shipment?.cityDeparture?.cityName }}, <br />
                    {{ shipment?.smartLockerDeparture?.idSmartLocker }}</span
                  >
                </div>
              </div>
            </ng-template>
          </div>

          <div class="item_separ">
            <span *ngIf="shipment?.addressDelivery !== null; else consigne">
              <div class="content_bloc">
                <div>
                  <img src="assets/images/png/home-icon.png" alt="" />
                </div>
                <div>
                  <p class="title_details">Arrivée:</p>
                  <span class="value_details"
                    >{{ shipment?.cityArrival?.cityName }},<br />
                    {{ shipment?.addressDelivery }}</span
                  >
                </div>
              </div>
            </span>
            <ng-template #consigne>
              <div class="content_bloc">
                <div style="text-align: center">
                  <img src="assets/images/svg/smartlocker.svg" alt="" />
                </div>
                <div>
                  <p class="title_details">Arrivée:</p>
                  <span class="value_details"
                    >Consigne :
                    {{ shipment?.smartLockerArrival?.idSmartLocker }} <br />
                    {{ shipment?.cityArrival?.cityName }}</span
                  >
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-7 card p-3 mt-4 shadow-sm details_shipement"
      style="float: none; margin: auto"
    >
    <div class="row">
      <div class="col-md-7 pt-rem-1">
        <h5> Détails de l'expédition : <strong> {{idShipment}} </strong></h5>
      </div>    
      <div class="col-md-5 text-end">
        <qrcode [qrdata]="idShipment" [width]="100" [errorCorrectionLevel]="'M'" [margin]="0">
        </qrcode>
      </div>
      </div>
      <hr>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Réference client </span>
        <span class="text-clr">{{ shipment?.idParcelClient }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Statut </span>
        <span class="text-clr"> {{ shipment?.currentStatus?.description }} </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Type livraison </span>
        <span class="text-clr">
          {{shipment?.addressDelivery !== null ? "Domicile" : "Consigne"}}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Paiement à la livraison </span>
        <span class="text-clr"> {{ shipment?.cod?.toFixed(0) }} dh </span>
      </div>
      <hr />

      <div
        class="d-flex align-items-center justify-content-between my-1 mt-2 mb-2"
      >
        <span class="text-gray"> Trajet </span>
        <span class="text-clr">
          De {{ shipment?.cityDeparture?.cityName }} à
          {{ shipment?.cityArrival?.cityName }}
        </span>
      </div>
      <hr />

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Taille du colis </span>
        <span class="text-clr">-</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Ouverture du colis </span>
        <span class="text-clr">
          {{ shipment?.allowOpenParcel !== false ? "Oui" : "Non" }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Nature du colis </span>
        <span class="text-clr">-</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Assurance</span>
        <span class="text-clr">{{
          shipment?.insureParcel !== false ? "Oui" : "Non"
        }}</span>
      </div>
      <hr />
      <h5> Expéditeur </h5>
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Nom </span>
        <span class="text-clr">
          {{
            shipment?.firstnameSender !== null ? shipment?.firstnameSender : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Prénom</span>
        <span class="text-clr">
          {{
            shipment?.lastnameSender !== null ? shipment?.lastnameSender : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">E-mail</span>
        <span class="text-clr">
          {{ shipment?.emailSender !== null ? shipment?.emailSender : "-" }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">GSM</span>
        <span class="text-clr">
          {{ shipment?.gsmSender?.replace("00212", "0") }}
        </span>
      </div>
      <hr />
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Départ</span>
        <span class="text-clr">{{ shipment?.cityDeparture?.cityName }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">
          {{ shipment?.addressCollect !== null ? "Adresse" : "N° consigne" }}
        </span>
        <span
          class="text-clr"
          *ngIf="shipment?.addressCollect !== null; else consignee"
        >
          <span
            *ngIf="
              (shipment?.addressCollect ?? '').includes('- info supp:');else notsplit">
            {{ (shipment?.addressCollect ?? "").split("- info supp:")[0] }}
          </span>
          <ng-template #notsplit>
            <span class="text-clr">{{ shipment?.addressCollect }}</span>
          </ng-template>
        </span>
        <ng-template #consignee>
          <span class="text-clr">
            {{ shipment?.smartLockerDeparture?.idSmartLocker }}
          </span>
        </ng-template>
      </div>

      <hr />
      <h5>Destinataire</h5>
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Nom</span>
        <span class="text-clr">
          {{
            shipment?.firstnameRecipient !== null
              ? shipment?.firstnameRecipient
              : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Prénom </span>
        <span class="text-clr">
          {{
            shipment?.lastnameRecipient !== null
              ? shipment?.lastnameRecipient
              : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">E-mail</span>
        <span class="text-clr">
          {{
            shipment?.emailRecipient !== null ? shipment?.emailRecipient : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> GSM </span>
        <span class="text-clr">
          {{
            shipment?.gsmRecipient !== null
              ? shipment?.gsmRecipient?.replace("00212", "0")
              : "-"
          }}
        </span>
      </div>
      <hr />

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Ville</span>
        <span class="text-clr"> {{ shipment?.cityArrival?.cityName }} </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">
          {{ shipment?.addressDelivery !== null ? "Adresse" : "N° consigne" }}
        </span>
        <span class="text-clr">
          {{
            shipment?.addressDelivery !== null
              ? shipment?.addressDelivery
              : shipment?.smartLockerArrival?.idSmartLocker
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Quartier </span>
        <span class="text-clr">-</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Secteur </span>
        <span class="text-clr">-</span>
      </div>
    </div>
  </div>

  <!-- tracking shipment -->
  <div class="col-md-3">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <span class="title_accord"> Suivi du colis </span>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <hr style="margin-top: -7px" />
          <div [ngClass]="trackingShipments.length >= 8 ? 'scroll' : ''">
            <div class="history-tl-container">
              <ul class="tl" style="width: 100%">
                <li
                  *ngFor="let track of trackingShipments"
                  class="tl-item {{
                    track.codeTracking.codesTracking.includes('ISSUE')
                      ? 'incident'
                      : track.codeTracking.codesTracking
                  }}"
                >
                  <div class="bloc_tracking">
                    <div class="item-title">
                      <p
                        milestoneTranslate="shipment.codes_tracking.{{track.codeTracking.codesTracking}}"
                        [translateValues]="{user: getUserName(track.user), operator: track.operator?? '-', date: (track.shipment.dateReschedule | formatMediumDate), refMMB: track.shipment.idMMBin}"
                      > 
                        {{ track.codeTracking.codesTracking }}
                      </p>
                      <span
                        *ngIf="track.codeTracking.codesTracking.includes('ISSUE')"
                      >
                        {{ track.codeTracking.description }}
                      </span>
                      <span
                        *ngIf="track.order"
                      >
                       ordre: <span style='color: #14027a;'><strong> {{ track.order.idOrder}} </strong></span>
                      </span>
                    </div>
                    <div class="timestamp">
                      <span>
                        {{ track.createdDate | formatMediumDatetime }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="title_accord"> Messages associés </span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <hr style="margin-top: -7px" />
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="title_accord"> Documents </span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <hr style="margin-top: -7px" />

          <div class="sidebar-menu-area">
            <ul class="sidebar-menu no-padding-left" id="sidebar-menu">
              <li *ngFor="let tracking of trackingShipments">
                <div *ngIf="tracking.urlFiles">
                  <a
                    class="pointer fw-bold p-0 m-0"
                    style="text-decoration: none"
                    (click)="isOpen = !isOpen"
                  >
                    <span 
                      milestoneTranslate="shipment.codes_tracking.{{tracking.codeTracking.codesTracking}}"
                      [translateValues]="{user: getUserName(tracking.user), operator: getUserName(tracking.order.user)}"
                    >
                      {{tracking.codeTracking.codesTracking}}
                    </span>
                    <i *ngIf="!isOpen" class="material-icons">chevron_right</i>
                    <i *ngIf="isOpen" class="material-icons">keyboard_arrow_down</i>
                  </a>
                  <ul *ngIf="isOpen">
                    <li
                      *ngFor="let urlFile of getDocumentsUrl(tracking.urlFiles)"
                    >
                      <a (click)="downloadImageByUrl(urlFile)" class="pointer p-0 m-0">
                        <span> {{ getDocumentName(urlFile) }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
