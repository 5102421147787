<div>
  <div class="d-flex justify-content-between align-items-center">
    <h2 class="text-lg fw-bold">Functions</h2>
  </div>
  <div class="mt-8">
    <milestone-table
      [columns]="columns"
      [data]="permissionFunctions"
      (rowClick)="handleRowAction($event)"
      [itemsPerPage]="pageInit"
      [dropdownFilters]="dropdownFilters"
      [customComponent]="customTemplate"
      [actionButtonText]="'Create Function'"
      [exportButtonText]="'Export Functions'"
      (actionClick)="onClickCreate()"
      (exportClick)="onExportClick()"
    ></milestone-table>
  </div>

  <milestone-modal
    [title]="'Create Function'"
    [icon]="'assets/icontclose.svg'"
    (closeModal)="handleClose()"
    *ngIf="isModalOpen"
  >
    <milestone-create-permission-functions-form
      (formSubmit)="handleFormSubmit($event)"
      (formCancel)="handleClose()"
    ></milestone-create-permission-functions-form>
  </milestone-modal>

  <ng-template #customTemplate>
    <!-- Custom component content goes here -->
    <div class="custom-content-table">
      <p>Custom content here for Functions</p>
    </div>
  </ng-template>
</div>
