import { LogicalOperation } from './logicalOperation';
import { QuerySearch } from './querySearch';

export interface IRequestSearch {
    querySearches?: QuerySearch[];  // Array of QuerySearch objects
    logicalOperation?: LogicalOperation;  // Assuming LogicalOperation is an enum or custom type
}

export class RequestSearch implements IRequestSearch {
    constructor(
    public querySearches?: QuerySearch[],
    public logicalOperation?: LogicalOperation
    ){}  
}
