import { Component, Input } from '@angular/core';
import { ITrackingOrderVM } from './models/tracking-order-vm.model';
import { IUserVM } from '../user/models/user-vm.model';

@Component({
  selector: 'milestone-tracking-order',
  templateUrl: './tracking-order.component.html',
  styleUrls: ['./tracking-order.component.scss']
})
export class TrackingOrderComponent {

  @Input() trackingOrders: ITrackingOrderVM[] = [];

  getUserName(user: IUserVM| null): string {
    return user? user.firstname + ' ' + user.lastname: '-' ;
  }

}
