import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LoginComponent],
  imports: [SharedModule,    RouterModule   ],
  
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoginModule {}
