import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'milestone-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
})
export class DropdownSelectComponent implements OnChanges {
  @Input() items: any[] = [];
  @Input() displayField = ''; // Type annotation removed
  @Output() itemSelected = new EventEmitter<any>();
  @Input() placeholder = 'Select';

  filteredItems = this.items; // Type annotation removed
  showDropdown = false; // Type annotation removed
  selectedValue = ''; // Type annotation removed

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  ngOnChanges(): void {
    this.filteredItems = this.items;
  }

  filterItems(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchText = inputElement.value.toLowerCase();
    this.filteredItems = this.items.filter((item) =>
      item[this.displayField].toLowerCase().includes(searchText)
    );
  }

  selectItem(item: any): void {
    this.selectedValue = item[this.displayField];
    this.itemSelected.emit(item);
    this.showDropdown = false;
  }
}
