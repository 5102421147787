
import { MatPaginatorIntl } from '@angular/material/paginator';

export class CustompaginationService extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Lignes par page:';
  
  // You can customize other paginator labels as well
  override nextPageLabel = 'Next';
  override previousPageLabel = 'Previous';
  override firstPageLabel = 'First';
  override lastPageLabel = 'Last';

  // Optional: customize the range label as well
  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 sur ${length}`;
    }

    const startIndex = page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);
    return `${startIndex + 1} - ${endIndex} sur ${length}`;
  };
}