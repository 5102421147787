import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AutocompleteGoogleService } from './autocomplete-google.service';

@Component({
  selector: 'milestone-autocomplete-google',
  templateUrl: './autocomplete-google.component.html',
  styleUrls: ['./autocomplete-google.component.scss'],
})
export class AutocompleteGoogleComponent implements OnInit {
  searchControl = new FormControl();
  predictions: any[] = [];

  @Output() placeSelected = new EventEmitter<any>();

  constructor(private googlePlacesService: AutocompleteGoogleService) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        // debounceTime(300),
        // distinctUntilChanged(),
        switchMap((input: string) =>
          this.googlePlacesService.getPlacePredictions(input)
        )
      )
      .subscribe(
        (response: any) => {
          this.predictions = response.predictions;
        },
        (error) => {
          console.error('Error fetching place predictions', error);
        }
      );
  }

  selectPlace(place: any) :void {
    this.placeSelected.emit(place);
  }
}
