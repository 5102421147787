export class Operation {
    public static readonly NOT_EQUALS = "NOT_EQUALS";
    public static readonly IS_NULL = "IS_NULL";
    public static readonly IS_NOT_NULL = "IS_NOT_NULL";
    public static readonly LIKE = "LIKE";
    public static readonly IN = "IN";
    public static readonly NOT_IN = "NOT_IN";
    public static readonly GREATER_THAN = "GREATER_THAN"; // >
    public static readonly LESS_THAN = "LESS_THAN"; // <
    public static readonly GREATER_THAN_OR_EQUALS = "GREATER_THAN_OR_EQUALS"; // >=
    public static readonly LESS_THAN_OR_EQUALS = "LESS_THAN_OR_EQUALS"; // <=
    public static readonly BETWEEN = "BETWEEN";
    public static readonly JOIN = "JOIN";
    public static readonly EQUALS = "EQUALS";
   
}