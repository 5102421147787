import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParamsComponent } from './list/params.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ParamsService } from './services/params.service';



@NgModule({
  declarations: [ParamsComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers:[ParamsService]

})
export class ParamsModule { }
