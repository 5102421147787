<div class="content-popup popup_affectation">
<div class="modal-header">
    <p class="title_popup">Affectation de l'ordre de ramassage: <strong>{{idOrder}}</strong></p>
</div>
<div class="grid_popup mt-4">
    <div class="content_info">
        <div class="blc1">
            
        <div class="bloc_infodetails">
        <div class="elem_colis">
        <p class="title_details"><strong>Nombre de colis:</strong></p>
        <span class="value_details">{{pickup?.currentCountShipment}} colis</span>         
        </div>
        <div ><span *ngIf="pickup?.address !== null; else adresse" class="elem_colis">
            <p class="title_details"><strong>Ramassage:</strong></p>
            <span class="value_details">En adresse</span>  
        </span>
        <ng-template #adresse>   
             <span  class="elem_colis">     
            <p class="title_details"><strong>Ramassage:</strong></p>
            <span class="value_details">En consigne</span></span> 
            </ng-template></div>
        </div>
        <div class="bloc_info mt-4" *ngIf="pickup?.address !== null">
            <i class="material-icons" style="font-size:18px">account_balance</i>
            <p><strong>Client</strong> : -</p>
        </div>
        
        <div class="bloc_info" *ngIf="pickup?.address !== null">
            <i class="material-icons" style="font-size:18px" >person</i>
            <p><strong>Nom du contact :</strong> {{pickup?.clientName}}</p>
        </div>
        <div class="bloc_info" *ngIf="pickup?.address !== null">
            <i class="material-icons" style="font-size:18px">phone</i>
            <p><strong>Téléphone du contact: </strong>{{replace002With0(pickup?.clientMobile?? "")}}</p>
        </div>
        <div class="bloc_info" *ngIf="pickup?.address !== null"><i class="material-icons" style="font-size:18px">navigation</i><p><strong>Quartier: </strong>{{pickup?.district}}</p></div>
        <div  [ngClass]="[pickup?.address !== null ? 'bloc_info' : 'bloc_info mt-8']">
            <i class="material-icons" style="font-size:18px">location_on</i>
            <p *ngIf="pickup?.address === null; else pointramassage"><strong>Consigne:</strong> {{pickup?.smartLocker?.idSmartLocker}}</p>
            <ng-template #pointramassage>
             <p><strong>Point de ramassage : </strong>
              <!--<span *ngIf="(pickup?.address ?? '').includes('- info supp:');else notsplit">{{(pickup?.address ?? '').split('- info supp:')[0]}}</span>-->
             <!--<ng-template #notsplit>--> 
                {{pickup?.address}}
              <!--</ng-template>-->
            </p>
            </ng-template>
            </div>
        <div class="bloc_info"><i class="material-icons" style="font-size:18px">home</i><p><strong>Ville: </strong>{{pickup?.city?.cityName}}</p></div>
    </div>
        <button (click)='openPopupAdresse()' class="btn-action-modal" style="width: auto;margin:10px auto" *ngIf="pickup?.address !== null">Modifier l'adresse</button>

    </div>

    <div>
        <p>Choisir un opérateur à qui affecter l'ordre</p>
        <select class="form-select" #teams (change)="onSelected(teams.value, getOperatorName(+teams.value))">
            <option  selected>Liste des ramasseurs</option>
            <option  *ngFor="let oper of operateurs" [value]="oper.idUser">
              {{ oper.firstname }} {{ oper.lastname }}
            </option>
        </select>
        <div class="blc2">
        <div class="mt-4">
        <div *ngIf="selectedTeam !== '' &&  selectedTeam !== 'Liste des ramasseurs' else nselected">
                <b style="font-family: Inter;
                font-size: 15px;
                font-weight: 500;
                line-height: 22.5px;
                letter-spacing: -0.023em;
                text-align: left;
                ">Ramasseur assigné</b>
                <br>
                   {{selectedTeam}}
        </div>
        <ng-template #nselected>
            <b style="font-family: Inter;
                font-size: 15px;
                font-weight: 500;
                line-height: 22.5px;
                letter-spacing: -0.023em;
                text-align: left;
                "> Aucun ramasseur assigné : <br>Veuillez sélectionner un ramasseur<br> dans la liste ci-dessus.
</b>
    </ng-template>
    </div>
</div>
        <button (click)='confirmAffectation()' class="btn-action-modal" 
         style="width: auto;margin:10px auto" [disabled]="(selectedTeam !== '' &&  selectedTeam !== 'Liste des ramasseurs')? false:true"
         >
         <ng-container *ngIf="!loading; else spinner">
            <span>Confirmer l'affectation</span>
          </ng-container>
          <ng-template #spinner>
            <div class="spinner-border text-light" role="status"></div>
          </ng-template>
        </button>
    
</div>
<button (click)="closeWithData()" class="close"><i class="material-icons">close</i></button>
</div>  

<milestone-modal
[title]="'Modifier l\'adresse'"
(closeModal)="handleClose()"
*ngIf="isModalOpen"
>
<milestone-address-modification
  [idOrder]="idOrder"
  (formSubmit)="handleFormSubmit($event)"
  (formCancel)="handleClose()"
></milestone-address-modification>
</milestone-modal>


<milestone-modal
[title]="'Confirmation'"
(closeModal)="handleClose2()"
*ngIf="isconfirmModalOpen"
>
<milestone-pickup-confirm-affectation  
(formSubmit)="handleFormSubmit2($event)"
(formCancel)="handleClose2()">
</milestone-pickup-confirm-affectation>
</milestone-modal>