import { Component } from '@angular/core';

@Component({
  selector: 'milestone-params',
  templateUrl: './params.component.html',
  styleUrls: ['./params.component.scss']
})
export class ParamsComponent {

}
