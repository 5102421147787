import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'milestone-modules-functions',
  templateUrl: './modules-functions.component.html',
  styleUrls: ['./modules-functions.component.scss']
})
export class ModulesFunctionsComponent implements OnInit {

  @Input() modulesFunctions: ModulesFunctionsVM[] = [];
  @Input() selectedFunctions: Set<FunctionDTO> = new Set<FunctionDTO>();
  @Input() profileName =  "";
  modalTitle = '';
  modalContent = '';

  isModalDescriptionOpen = false;



  ngOnInit(): void {
    this.modulesFunctions.forEach(item => {
      item.functions.forEach(func => {
        if (this.selectedFunctions.has(func)) {
          func.selected = true;
        }
      });
    });

    
    this.modulesFunctions.forEach(item => {
      item.functions.forEach(func => {
        if (func.selected) {
          this.selectedFunctions.add(func);
        }
      });
    });

    this.modulesFunctions.forEach(module => module.expanded = false);
  }

  toggleModuleSelection(item: ModulesFunctionsVM): void {
    this.toggleModule(item.module);
    const isSelected = item.module.selected;
    item.functions.forEach(func => {
      func.selected = isSelected;
      this.updateSelectedFunctions(func, isSelected ?? false);
    });
  }

  toggleFunctionSelection(func: FunctionDTO, item: ModulesFunctionsVM): void {
    const isSelected = func.selected;
    this.updateSelectedFunctions(func, isSelected ?? false);

    const allSelected = item.functions.every(f => f.selected);
    item.module.selected = allSelected;
  }

  updateSelectedFunctions(func: FunctionDTO, isSelected: boolean): void {
    if (isSelected) {
      this.selectedFunctions.add(func);
    } else {
      this.selectedFunctions.delete(func);
    }
  }

  toggleModule(module: ModuleDTO): void {
    const moduleVM = this.modulesFunctions.find(mf => mf.module.id === module.id);
    if (moduleVM) {
      moduleVM.expanded = !moduleVM.expanded;
    }
  }

  onGlobalSearch(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchTerm = inputElement.value.toLowerCase();

    // Filter modules and change order of ModulesFunctionsVM array and if text is found in functions or description of function then filter the functions array else return all functions of that module
    this.modulesFunctions = this.modulesFunctions.map(module => {
      const functions = module.functions.filter(func =>
        func.functions.toLowerCase().includes(searchTerm) || func.description.toLowerCase().includes(searchTerm)
      );
      return { ...module, functions };
    });
   
  }


  handleClose(): void {
    this.isModalDescriptionOpen = false;
  }

  onOpenDescription(func: FunctionDTO)  : void {
    this.modalTitle = `Description of ${func.functions}`;
    this.modalContent = `${func.description}`;
    this.isModalDescriptionOpen = true;
  }


}