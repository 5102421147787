import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnConfig } from '../table/column_table_model';

@Component({
  selector: 'milestone-column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss'],
})
export class ColumnSelectorComponent {
  columns: Array<ColumnConfig>;
  selectedColumns: Array<ColumnConfig>;

  constructor(
    public dialogRef: MatDialogRef<ColumnSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.columns = data.columns;
    this.selectedColumns = data.selectedColumns;
  }

  isSelected(column: any): boolean {
    return this.selectedColumns.includes(column);
  }

  applySelection(): void {
    this.dialogRef.close(this.selectedColumns);
  }
}
