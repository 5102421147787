export enum WebsocketTypes {
  ERROR = 'ERROR', // Assign string values explicitly
  ACTION = 'ACTION',
  NOTIFICATION = 'NOTIFICATION',
  NOTIFICATION_RELOAD = 'NOTIFICATION_RELOAD',
}

export interface WebsocketMessage {
  sender: string;
  receiver: string;
  title: string;
  message: string;
  type: WebsocketTypes;
  action: string;
}
