import { Injectable } from '@angular/core';
import { LogicalOperation } from './logicalOperation';
import { QuerySearch } from './querySearch';
import { RequestSearch } from './requestSearch';

@Injectable({
  providedIn: 'root',
})
export class RequestSearchService {
  private querySearches?: QuerySearch[] | null;

  constructor() {}

  /**
   * Adds a search query to the list
   * @param column - The name of the column to search
   */
  public addSearchQuery(querySearch: QuerySearch): void {
    if (this.querySearches == null) {
      this.querySearches = [];
    }

    this.querySearches.push(querySearch);
  }

  /**
   * Builds the final RequestSearch object
   * @param logicalOperation - The logical operation to apply to the queries (e.g. AND, OR)
   * @returns {RequestSearch} - The generated RequestSearch object
   */
  public buildRequestSearch(logicalOperation: LogicalOperation): RequestSearch {
    if (this.querySearches == null) {
      this.querySearches = [];
    }
    return new RequestSearch(this.querySearches, logicalOperation);
  }

  /**
   * Clears the current search queries, allowing for a fresh search to be built
   */
  public clearQueries(): QuerySearch[] {
    this.querySearches = [];
    return this.querySearches;
  }
}
