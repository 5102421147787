<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div
    class="alert alert-danger"
    *ngIf="createShelvingError"
    data-cy="loginError"
  >
    <strong>Create Shelving!</strong> {{ errorShelving }}
  </div>

  <div>
    <div class="form-group">
      <label class="label" for="sector">Shelving</label>
      <input
        id="idShelving"
        formControlName="idShelving"
        type="text"
        class="form-control"
        placeholder="idShelving"
      />
    </div>

    <div class="form-group">
      <label class="label" for="sector">Capacity</label>
      <input
        id="capacity"
        formControlName="capacity"
        type="text"
        class="form-control"
        placeholder="42"
      />
    </div>

    <div class="form-group">
      <label class="label" for="agency">Agency</label>
      <milestone-dropdown-select
        [items]="agencies"
        displayField="agencyName"
        placeholder="Select Agency"
        (itemSelected)="selectAgency($event)"
      >
      </milestone-dropdown-select>
    </div>

    <div class="form-group">
      <label class="label" for="geom">Location</label>
      <input
        id="location"
        formControlName="location"
        type="text"
        class="form-control"
        placeholder="Location"
      />
    </div>
  </div>

  <div class="form-check">
    <input
      id="confirmation"
      formControlName="confirmation"
      type="checkbox"
      class="form-check-input"
    />
    <label for="confirmation" class="form-check-label">Yes, confirmation</label>
  </div>

  <div class="form-group mt-3">
    <button type="submit" class="btn-action-modal">
      <ng-container *ngIf="!loading; else spinner">
        <span>Create Shelving</span>
      </ng-container>
      <ng-template #spinner>
        <div class="spinner-border text-light" role="status"></div>
      </ng-template>
    </button>
  </div>
</form>
