import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstant } from '../app-constant';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  
  downloadFile(fileUrl: string): Observable<Blob> {
    return this.http.get(fileUrl, {
      headers: AppConstant.httpOptions.headers, // Includes JWT for secured endpoints
      responseType: 'blob' // This specifies the response is a Blob (binary large object)
    });
  }
}
