import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationConfigService } from '../../../core/config/application-config.service';
import { Observable } from 'rxjs';
import { ITypeCollectVM } from '../models/type-collect-vm.model';

@Injectable({
  providedIn: 'root'
})
export class TypeCollectService {

  base_url = "/typeCollects"

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationConfigService
  ) { }

  getAll(): Observable<ITypeCollectVM[]>{
    return this.http.get<ITypeCollectVM[]>(this.applicationService.getEndpointFor(`${this.base_url}/all`));
  }
}
