import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ZoneService } from '../services/zones.service';
import { CreateZoneVM } from '../models/create-zone-vm.model';

@Component({
  selector: 'milestone-create-zone',
  templateUrl: './create-zone.component.html',
  styleUrls: ['./create-zone.component.scss'],
})
export class CreateZoneComponent implements OnInit {
  userForm: FormGroup;

  createZonesError = false;
  errorZone = '';
  loading = false;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private zoneService: ZoneService
  ) {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      confirmation: [false, Validators.required],
    });
  }

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.userForm.valid && this.userForm.value.confirmation) {
      console.log('Form Submitted', this.userForm.value);
      this.loading = true;

      this.zoneService
        .createZone(
          new CreateZoneVM(
            this.userForm.value.name,
            this.userForm.value.description
          )
        )
        .subscribe(
          () => {
            this.notification.showMessageSuccess('Zone created successfully');
            this.loading = false;
            this.formSubmit.emit(this.userForm.value);
          },
          (error) => {
            this.createZonesError = true;
            this.errorZone = error.error.message;
            this.loading = false;

            this.notification.showMessageError('Error creating zone');
          }
        );
    } else {
      this.notification.showMessageError('Please fill in all required fields');
    }
  }

  cancel(): void {
    this.formCancel.emit();
  }
}
