<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div
    class="alert alert-danger"
    *ngIf="createFunctionError"
    data-cy="loginError"
  >
    <strong>Create Functions!</strong> {{ errorFunction }}
  </div>

  <div class="form-group">
    <label class="label" for="sector">Function name</label>
    <input
      id="functions"
      formControlName="functions"
      type="text"
      class="form-control"
      placeholder="Creating users"
    />
  </div>

  <div class="form-group">
    <label class="label" for="module">Module</label>
    <milestone-dropdown-select
      [items]="permissionModules"
      displayField="modules"
      placeholder="Select Module"
      (itemSelected)="selectModule($event)"
    >
    </milestone-dropdown-select>
  </div>

  <div class="form-group">
    <label class="label" for="geom">Description</label>
    <input
      id="description"
      formControlName="description"
      type="text"
      class="form-control"
      placeholder="Description"
    />
  </div>

  <div class="form-check">
    <input
      id="confirmation"
      formControlName="confirmation"
      type="checkbox"
      class="form-check-input"
    />
    <label for="confirmation" class="form-check-label">Yes, confirmation</label>
  </div>

  <div class="form-group mt-3">
    <button type="submit" class="btn-action-modal">
      <ng-container *ngIf="!loading; else spinner">
        <span>Create Function</span>
      </ng-container>
      <ng-template #spinner>
        <div class="spinner-border text-light" role="status"></div>
      </ng-template>
    </button>
  </div>
</form>
