import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SideBareComponent } from './sidebare/side-bare.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { NotificationService } from '../shared/notification/notification.service';
import { WebsocketService } from '../core/config/websocket.service';

@NgModule({
  declarations: [SideBareComponent, LayoutComponent, HeaderComponent],
  imports: [
    CommonModule,
    // AngularMaterialModule,
    RouterModule,
    // MatSidenavModule,
    // SharedModule,

  ],

  providers: [
    WebsocketService,
    NotificationService,

  ],
  exports: [SideBareComponent, HeaderComponent, LayoutComponent, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule {}
