<div class="operator-map-card" *ngIf="operator">
    <div class="profile-info">
      <p>{{ operator.firstname }}</p>
      <p> Casa </p>
      <p> Sector </p>
      <p>{{ operator.lastname }}</p>
      <div class="backnmbrcolis">
        <img src="assets/images/png/box-solid-white.png" alt="" />&nbsp;&nbsp;{{operator.countOrder}}
      </div>
    </div>
  </div>