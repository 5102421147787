import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IndicatorCardTransportVM } from './indicator-card-transport.model';
@Component({
  selector: 'milestone-indicator-card-transport',
  templateUrl: './indicator-card-transport.component.html',
  styleUrls: ['./indicator-card-transport.component.scss']
})
export class IndicatorCardTransportComponent {
  @Input() indicator!: IndicatorCardTransportVM;

  // Create an EventEmitter to emit the clicked title to the parent
  //@Output() indicatorClicked: EventEmitter<string> = new EventEmitter<string>();

  // Method to handle click and emit the title to the parent
  onClick(): void {
   // this.indicatorClicked.emit(this.indicator.title);
  }
}
