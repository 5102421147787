<div>
  <input
    type="text"
    [formControl]="searchControl"
    placeholder="Enter your address"
    class="form-control"
  />

  <ul *ngIf="predictions.length > 0" class="autocomplete-suggestions">
    <li
      *ngFor="let prediction of predictions"
      (click)="selectPlace(prediction)"
    >
      {{ prediction.description }}
    </li>
  </ul>
</div>
