import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { OrderService } from '../../order/services/order-service.service';

@Component({
  selector: 'milestone-address-modification',
  templateUrl: './address-modification.component.html',
  styleUrls: ['./address-modification.component.scss'],
})
export class AddressModificationComponent implements AfterViewInit {
  @Input() adressType!: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput = '';
  queryWait!: boolean;

  createSectorError = false;
  errorSector = '';
  location = '';
  adress= '';
  district: string | null = null;
  quartierr: string | null | undefined = '';
  loading = false;
  submitted = false;
  pres_adresse = false;
  isModalOpen = false;
  selectedPlace!: google.maps.places.PlaceResult | null;


  addressForm = this.fb.group({
    address: ['', [Validators.required]],
    quartier: ['',[Validators.required]],
    description: [''],
    latitude: ['', [Validators.required]],
    longitude: ['', [Validators.required]],
    // confirmation: [false, Validators.requiredTrue],
  });

  @Input() idOrder: any;
  @ViewChild('addressInput', { static: false }) addressInput!: ElementRef;

  constructor(
    private fb: FormBuilder,
    protected activeModal: NgbActiveModal,
    private notification: NotificationService,
    private orderService: OrderService
  ) {}

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
    
  }

  invokeEvent(place: object): void {
    this.setAddress.emit(place);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.addressForm.controls;
  }


 // send data
  onSubmit(): void {
    
    if (!this.selectedPlace) {
      this.addressControl?.setErrors({ invalid: true });
      this.loading = false;
    }

    if (this.addressForm.valid) {
      this.pres_adresse = false;
     
      const lat = this.addressForm.get('latitude')?.value ?? '';
      const lng = this.addressForm.get('longitude')?.value ?? '';
      this.location = `${lat},${lng}`;

      if(this.addressForm.get('description')?.value  !== ''){
      // this.adress=this.addressForm.get('address')?.value +' - info supp:'+ this.addressForm.get('description')?.value;
      this.adress=`${this.addressForm.get('address')?.value?? ''} - info supp:${this.addressForm.get('description')?.value?? ''}`;

      }else{
       this.adress=this.addressForm.get('address')?.value ?? '';
      }
      
     this.orderService
        .updateAdress(
          this.idOrder,
          this.adress,
          this.addressForm.get('quartier')?.value ?? '',
          this.location
        )
        .subscribe({
          next: () => {
            this.activeModal.close(this.idOrder);
            this.notification.showMessageSuccess('Adresse a bien été modifié');
            this.loading = false;
          },
          error: (err) => {
            this.notification.showMessageError(`error update adress: ${String(err.error.message)}`);
            this.loading = false;
          },
        });
      this.addressForm.get('latitude')?.setValue('');
      this.loading = true;
    }else {
      this.pres_adresse = true;
    }
    this.submitted = true;
    this.isModalOpen = false;
    this.idOrder;
  }

  get addressControl():any {
    return this.addressForm.get('address');
  }
  
  onInputChange(event: any): void {
    // Clear selected place if manual input occurs
    if (event.target.value !== this.selectedPlace?.formatted_address) {
      this.selectedPlace = null;
      this.addressControl?.setErrors({ invalid: true });  // Set error if the address isn't selected
    }
  }

  private getPlaceAutocomplete(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'MA' },
        types: [this.adressType], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
     
      this.invokeEvent(place);
      if (place.formatted_address) {
      this.addressForm.get('address')?.setValue(place.formatted_address);
      this.pres_adresse = false;
      const latitude = place.geometry?.location?.lat();
      const longitude = place.geometry?.location?.lng();
      this.quartierr=this.getDistrict(place);
      // Ensure latitude and longitude are strings or null
      this.addressForm
        .get('latitude')
        ?.setValue(latitude !== undefined ? latitude.toString() : null);
      this.addressForm
        .get('longitude')
        ?.setValue(longitude !== undefined ? longitude.toString() : null);
      this.addressForm
      .get('quartier')
      ?.setValue(this.quartierr !== undefined ? this.quartierr : null);

      this.selectedPlace = place;
        this.addressControl?.setValue(place.formatted_address);
      }else {
        this.selectedPlace = null;
      }
     
    });
  }
 

// get district
 private getDistrict(place: google.maps.places.PlaceResult) :any {
   // const place = this.autocomplete.getPlace();
    if (!place.address_components) {
      return;
    }
    // Parcourir les composants d'adresse pour trouver le quartier (sublocality_level_1 ou neighborhood)
    const addressComponents = place.address_components;
    const neighborhoodComponent = addressComponents.find((component) =>
      component.types.includes('sublocality_level_1') || component.types.includes('neighborhood')
    );

    this.district = neighborhoodComponent ? neighborhoodComponent.long_name : '';
    return  this.district;
  }
}
