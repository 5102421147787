import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOperatorVM } from 'src/app/modules/location/model/operator-indicators.model';


@Component({
  selector: 'milestone-operator-details',
  templateUrl: './operator-details.component.html',
  styleUrls: ['./operator-details.component.scss']
})
export class OperatorDetailsComponent {
  @Input() operator: IOperatorVM | undefined  | null;
}
