import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PermissionModuleVM } from './models/permission_module_vm';
import { PermissionModuleService } from './services/permissions-module.service';

@Component({
  selector: 'milestone-permissions-module',
  templateUrl: './permissions-module.component.html',
  styleUrls: ['./permissions-module.component.scss'],
})
export class PermissionsModuleComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'Module', field: 'modules' },
    { header: 'Description', field: 'description' },
    { header: 'Created by', field: 'createdBy' },
    { header: 'Created date', field: 'createdDate' },
    { header: 'Last modified by', field: 'lastModifiedBy' },
    { header: 'Last modified date', field: 'lastModifiedDate' },
  ];

  permissionModules: PermissionModuleVM[] = [];

  dropdownFilters = [];

  constructor(
    private permissionModuleService: PermissionModuleService,
    private router: Router,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadZones();
  }

  loadZones(): void {
    this.permissionModuleService.getPermissionModules().subscribe(
      (response) => {
        this.permissionModules = response.content;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      () => {
        this.notification.showMessageError('Error loading permissionModules');
      }
    );
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }

  onEdit(rowData: any): void {
    // Handle edit logic here
    console.log('Edit clicked for row:', rowData);
  }

  onDelete(rowData: any): void {
    // Handle delete logic here
    console.log('Delete clicked for row:', rowData);
  }
}
