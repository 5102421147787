import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit,Component,ElementRef,OnInit,ViewChild} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import 'bootstrap-daterangepicker';
import $ from 'jquery';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import 'moment/locale/fr'; // Importer la locale française
import * as XLSX from 'xlsx';
import { Pagination } from '../../../core/request/request.model';
import { ColumnType } from '../../../core/specification/columnType';
import { LogicalOperation } from '../../../core/specification/logicalOperation';
import { Operation } from '../../../core/specification/operation';
import { QuerySearch } from '../../../core/specification/querySearch';
import { RequestSearchService } from '../../../core/specification/request-search.service';
import { RequestSearch } from '../../../core/specification/requestSearch';
import { LayoutService } from '../../../layout/layout.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { FunctionFiltters } from '../../../shared/utils/status-shipment-filter';
import { ICityVM } from '../../city/models/city-vm.model';
import { CitiesService } from '../../city/services/cities.service';
import { ICodeTrackingVM } from '../../code-tracking/models/code-tracking-vm.model';
import { CodeTrackingService } from '../../code-tracking/services/code-trakcing.service';
import { ExportDataShipement } from '../../pickup-operations/models/expoer-data.model';
import { ITypeCollectVM } from '../../type-collect/models/type-collect-vm.model';
import { TypeCollectService } from '../../type-collect/services/type-collect.service';
import { IndicatorShipmentVM } from '../indicator-card/indicator-shipment-card.model';
import { IShipmentVM } from '../models/shipment-vm.model';
import { ShipementService } from '../services/shipement.service';
import { ITEMS_PER_PAGE } from 'src/app/core/config/pagination.constants';
import { FormatMediumDatetimePipe } from 'src/app/shared/date/format-medium-datetime.pipe';
@Component({
  selector: 'milestone-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss'],
  providers: [FormatMediumDatetimePipe] // Provide the pipe here
})
export class ShipmentListComponent implements OnInit, AfterViewInit {
  cities: ICityVM[] = [];
  statutShipment: ICodeTrackingVM[] = [];
  typesCollects: ITypeCollectVM[] = [];
  requestSearch?: RequestSearch | null;

  totalItems = 0;
  pageSize = ITEMS_PER_PAGE;
  page = 0;

  isShowDivIf = false;

  displayedColumns: string[] = [
    'select',
    'Date de création',
    'Référence ordre',
    'Référence expédition',
    'Référence client',
    'Type ramassage',
    'Ville depart',
    'Ville arrivee',
    'Id client',
    'Client',
    'Statut',
    'date de modification',
    'action',
  ];

  allColumns: string[] = this.displayedColumns;
  visibleColumns = this.displayedColumns;

  indicators: IndicatorShipmentVM[] = [];
  valuesArray: any[] = [];

  dataSource = new MatTableDataSource<IShipmentVM>([]);
  selection = new SelectionModel<IShipmentVM>(true, []);
  @ViewChild('TABLE') table!: ElementRef;

  // filters
  idShipement: number | null = null;
  idParcelClient = '';
  idOrder = '';
  idsCityDeparture = [];
  idsCityArrival = [];
  clientName = '';
  clientId?: number | null;
  selectedSatatus = '';
  selectedTypeCollect = '';
  startDate?: string | null;
  endDate?: string | null;
  filtersIndicator: string[] = [];
  sortField = 'createdDate';
  sortOrder = 'desc';
  showFiltre = false;

  // Create an instance of FunctionFiltters
  functionFiltters = new FunctionFiltters();

  constructor(
    private shipementService: ShipementService,
    private requestSearchService: RequestSearchService,
    private notification: NotificationService,
    private citiesService: CitiesService,
    private codeTrackingService: CodeTrackingService,
    private typeCollectService: TypeCollectService,
    private layoutService: LayoutService,
    protected formatMediumDatetimePipe: FormatMediumDatetimePipe
  ) {}

  ngOnInit(): void {
    this.getIdicatorsPickup();
    this.getAllCity();
    this.getAllTypeCollect();
    this.getStatutShipment();
    this.getShipments();

    $('.mat-mdc-text-field-wrapper').css({
      background: 'white',
      height: '46px',
      width: '100%',
    });

    this.layoutService.onMessagePickup().subscribe((resp) => {
      setTimeout(() => {
        this.getIdicatorsPickup();
        this.getShipments();
      }, 500);
    });
  }

  ngAfterViewInit(): void {
    moment.locale('fr');
    setTimeout(() => {
      this.initializeDateRangePicker();
    }, 100); // Configurer moment pour utiliser le français
  }

  onFiltersChange(): void {
    this.page = 0;
    this.totalItems = 0;
    this.getShipments();
    this.selection.clear();
  }

  getShipments(): void {
    this.setQuery();
    const sort = [`${this.sortField},${this.sortOrder}`];
    const pagination = this.setPagination(this.page, this.pageSize, sort);

    this.shipementService.query(pagination, this.requestSearch).subscribe({
      next: (response) => {
        this.dataSource.data = response.content;
        this.totalItems = response.totalElements;
      },
      error: (err) => this.notification.showMessageError(err.error.message),
    });
  }

  isVisible(column: string): boolean {
    return this.visibleColumns.includes(column);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  toggleDisplayDivIf(): void {
    this.isShowDivIf = !this.isShowDivIf;
  }

  onTableDataChange(event: any): void {
    this.page = Number(event.pageIndex);
    this.pageSize = event.pageSize;
    this.getShipments();
    this.table.nativeElement.scrollIntoView();
    this.selection.clear();
  }

  onSort(sort: Sort): void {
    this.sortField = sort.active;
    this.sortOrder = sort.direction;
    this.getShipments();
  }

  replace002With0(input: any): any {
    return input.replace(/00212/g, '0');
  }

  ExportToExcel(): void {
    if (this.selection.selected.length === 0) {
      alert('No rows selected');
      return;
    }

    const exportData = this.selection.selected.map((row) => {
      const dataObject: ExportDataShipement = {}; // Create an empty object to build

      if (this.isVisible('Date de création')) {
        dataObject.Date_de_création = this.formatMediumDatetimePipe.transform(row.createdDate);
      }

      if (this.isVisible('Référence expédition')) {
        dataObject.Ref_expédition = row.idShipment.toFixed(0);
      }

      if (this.isVisible('Référence client')) {
        dataObject.Ref_client = row.idParcelClient;
      }

      if (this.isVisible('Type ramassage')) {
        dataObject.type_ramassage = row.typeCollect.description;
      }

      if (this.isVisible('Ville depart')) {
        dataObject.Ville_de_départ = row.cityDeparture.cityName;
      }

      if (this.isVisible('Ville arrivee')) {
        dataObject.Ville_arrivé = row.cityArrival.cityName;
      }

      if (this.isVisible('Id client')) {
        dataObject.Id_client = row.idExternalClient;
      }

      if (this.isVisible('Client')) {
        dataObject.Client =
          row.companySender === ''
            ? '-'
            : `${row.companySender} ${row.gsmSender}`;
      }

      if (this.isVisible('Statut')) {
        dataObject.Statut = row.currentStatus.description;
      }
      return dataObject; // Return the constructed object
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SelectedRows');
    XLSX.writeFile(workbook, 'ramassage.xlsx');
  }

  exportToPDF(): void {
    if (this.selection.selected.length === 0) {
      alert('No rows selected');
      return;
    }

    const doc = new jsPDF();

    // Create a table for the selected rows
    const exportData = this.selection.selected.map((row) => {
      const dataObject: ExportDataShipement = {};
      this.valuesArray = [];

      if (this.isVisible('Date de création')) {
        dataObject.Date_de_création = this.convertTimestampToFormattedDate(
          row.createdDate
        );
      }

      if (this.isVisible('Référence expédition')) {
        dataObject.Ref_expédition = row.idShipment.toFixed(0);
      }

      if (this.isVisible('Référence client')) {
        dataObject.Ref_client = row.idParcelClient;
      }

      if (this.isVisible('Type ramassage')) {
        dataObject.type_ramassage = row.typeCollect.description;
      }

      if (this.isVisible('Ville depart')) {
        dataObject.Ville_de_départ = row.cityDeparture.cityName;
      }

      if (this.isVisible('Ville arrivee')) {
        dataObject.Ville_arrivé = row.cityArrival.cityName;
      }

      if (this.isVisible('Id client')) {
        dataObject.Id_client = row.idExternalClient;
      }

      if (this.isVisible('Client')) {
        dataObject.Client =
          row.companySender === ''
            ? '-'
            : `${row.companySender} ${row.gsmSender}`;
      }

      if (this.isVisible('Statut')) {
        dataObject.Statut = row.currentStatus.description;
      }
      return dataObject;
    });

    exportData.forEach((item) => {
      this.valuesArray.push(Object.values(item));
    });

    const columnNames = Object.keys(exportData[0]);

    autoTable(doc, {
      head: [columnNames],
      body: this.valuesArray,
    });

    // Save the PDF
    doc.save('ramassage.pdf');
  }

  redirect(val: string | null): void {
    this.selectedSatatus = '';

    switch (val) {
      case 'Ramassages non affectés':
        this.filtersIndicator =
          this.functionFiltters.getPickupInfoNotAssigned();
        this.onFiltersChange();
        break;

      case 'Ramassages en cours':
        this.filtersIndicator = this.functionFiltters.getPickupInfoAssigned();
        this.onFiltersChange();
        break;

      case 'Ramassages terminé':
        this.filtersIndicator = this.functionFiltters.getPickedUp();
        this.onFiltersChange();
        break;

      case 'Ramassages avec incident':
        this.filtersIndicator = this.functionFiltters.getPickupIssue();
        this.onFiltersChange();
        break;

      case 'Colis annulé':
        this.filtersIndicator = this.functionFiltters.getPickupCanceled();
        this.onFiltersChange();
        break;

      case 'Colis reprogrammée':
        this.filtersIndicator = this.functionFiltters.getPickupRescheduled();
        this.onFiltersChange();
        break;

      default:
        this.getShipments();
        break;
    }
  }

  showHideBelowFiltre(): void {
    moment.locale('fr'); // Configurer moment pour utiliser le français
    setTimeout(() => {
      this.initializeDateRangePicker();

      $('.mat-mdc-text-field-wrapper').css({
        background: 'white',
        height: '46px',
      });
    }, 100); //

    this.showFiltre = !this.showFiltre;
  }

  /* ========================
          PRIVATE METHODS
     ========================= */

  convertTimestampToFormattedDate(timestampInSeconds: number): string {
    const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
    const dd: string = this.padZero(date.getDate());
    const mm: string = this.padZero(date.getMonth() + 1); // Months are 0-based
    const yyyy: string = date.getFullYear().toString();
    const hours: string = this.padZero(date.getHours());
    const minutes: string = this.padZero(date.getMinutes());
    const seconds: string = this.padZero(date.getSeconds());

    return `${dd}-${mm}-${yyyy} ${hours}:${minutes}:${seconds}`;
  }

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  private getStatutShipment(): void {
    this.codeTrackingService.getAllCodeTrakingsForWeb().subscribe({
      next: (response) => (this.statutShipment = response),
      error: () =>
        this.notification.showMessageError('Error loading sttaus order'),
    });
  }

  private initializeDateRangePicker(): void {
    (<any>$('#daterange')).daterangepicker(
      {
        startDate: moment().startOf('year'),
        endDate: moment(),
        opens: 'right',
        locale: {
          cancelLabel: 'Annuler',
          applyLabel: 'Appliqué',
          customRangeLabel: 'Personnalisé',
          toLabel: '~',
          format: 'DD/MM/YYYY',
        },
        ranges: {
          "Aujourd'hui": [moment(), moment()],
          Hier: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          '7 Derniers Jours': [moment().subtract(6, 'days'), moment()],
          '30 Derniers Jours': [moment().subtract(29, 'days'), moment()],
          'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
          'Mois Dernier': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
          'Cette année': [moment().startOf('year'), moment().endOf('year')],
          "L'année dernière": [
            moment().startOf('year').subtract(1, 'year'),
            moment().endOf('year').subtract(1, 'year'),
          ],
        },
      },
      (start: any, end: any) => {
        this.startDate = start.format('YYYY-MM-DD');
        this.endDate = end.format('YYYY-MM-DD');
        this.onFiltersChange();
      }
    );
  }

  private getAllCity(): void {
    this.citiesService.getAllCities().subscribe({
      next: (response) => {
        this.cities = response;
      },
      error: () => {
        this.notification.showMessageError('Error loading Cities');
      },
    });
  }

  private getAllTypeCollect(): void {
    this.typeCollectService.getAll().subscribe({
      next: (response) => {
        this.typesCollects = response;
      },
      error: () => {
        this.notification.showMessageError('Error loading Cities');
      },
    });
  }

  private setQuery(): void {
    this.requestSearchService.clearQueries();

    this.requestSearch = this.getFilters();

    if (this.startDate && this.endDate) {
      const fromDate = new Date(this.startDate);
      const formatFromDate = this.formatDateToYYYYMMDD(fromDate) + ' 00:00:00';

      const toDate = new Date(this.startDate && this.endDate);
      const formatToDate = this.formatDateToYYYYMMDD(toDate) + ' 23:59:59';

      let query: QuerySearch = {
        column: 'createdDate',
        value: formatFromDate,
        operation: Operation.GREATER_THAN_OR_EQUALS,
        columnType: ColumnType.DATE,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
      query = {
        column: 'createdDate',
        value: formatToDate,
        operation: Operation.LESS_THAN_OR_EQUALS,
        columnType: ColumnType.DATE,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };
      this.requestSearch = this.getFilters(query);
    }

    if (this.idShipement !== null) {
      const query: QuerySearch = {
        column: 'idShipment',
        value: this.idShipement,
        operation: Operation.EQUALS,
        columnType: ColumnType.LONG,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.idParcelClient !== '') {
      const query: QuerySearch = {
        column: 'idParcelClient',
        value: this.idParcelClient,
        operation: Operation.LIKE,
        columnType: ColumnType.STRING,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.idOrder !== '') {
      const query: QuerySearch = {
        column: 'idOrder',
        value: this.idOrder,
        operation: Operation.LIKE,
        columnType: ColumnType.STRING,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // city departure
    if (this.idsCityDeparture.length > 0) {
      const ids = this.idsCityDeparture.join(',');
      const query: QuerySearch = {
        column: 'idCity',
        value: ids,
        operation: Operation.JOIN,
        columnType: ColumnType.INTEGER,
        joinTable: 'cityDeparture',
        operationJoin: Operation.IN,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // city arrival
    if (this.idsCityArrival.length > 0) {
      const ids = this.idsCityArrival.join(',');
      const query: QuerySearch = {
        column: 'idCity',
        value: ids,
        operation: Operation.JOIN,
        columnType: ColumnType.INTEGER,
        joinTable: 'cityArrival',
        operationJoin: Operation.IN,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // type collect from address
    if (this.selectedTypeCollect !== '') {
      const query: QuerySearch = {
        column: 'typeCollects',
        value: this.selectedTypeCollect,
        operation: Operation.JOIN,
        columnType: ColumnType.ENUM,
        joinTable: 'typeCollect',
        operationJoin: Operation.EQUALS,
        enumClassName: 'TypeCollects',
      };

      this.requestSearch = this.getFilters(query);
    }

    // client
    if (this.clientName !== '') {
      let query: QuerySearch = {
        column: 'firstnameSender',
        value: this.clientName,
        operation: Operation.LIKE,
        columnType: ColumnType.STRING,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);

      query = {
        column: 'lastnameSender',
        value: this.clientName,
        operation: Operation.LIKE,
        columnType: ColumnType.STRING,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // idExternalClient
    if (this.clientId) {
      const query: QuerySearch = {
        column: 'idExternalClient',
        value: this.clientId,
        operation: Operation.EQUALS,
        columnType: ColumnType.INTEGER,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.selectedSatatus !== '') {
      this.filtersIndicator = [];
      const query: QuerySearch = {
        column: 'codesTracking',
        value: this.selectedSatatus,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        joinTable: 'currentStatus',
        operationJoin: Operation.EQUALS,
        enumClassName: 'null',
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.filtersIndicator.length > 0) {
      this.selectedSatatus = '';
      const filterIndicator = this.filtersIndicator.join(',');

      const query: QuerySearch = {
        column: 'codesTracking',
        value: filterIndicator,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        joinTable: 'currentStatus',
        operationJoin: Operation.IN,
        enumClassName: 'null',
      };

      this.requestSearch = this.getFilters(query);
    }

  }

  private formatDateToYYYYMMDD(date: Date): string {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(date.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
  }

  private getFilters(query?: QuerySearch): RequestSearch {
    if (query) {
      this.requestSearchService.addSearchQuery(query);
    }

    return this.requestSearchService.buildRequestSearch(LogicalOperation.AND);
  }

  private setPagination(
    page: number,
    size: number,
    sort?: string[] | null
  ): Pagination {
    return {
      page,
      size,
      sort,
    };
  }

  private getIdicatorsPickup(): void {
    this.shipementService.getIdicatorsShipment().subscribe({
      next: (response) => (this.indicators = response),
      error: (err) => this.notification.showMessageError(err.error.message),
    });
  }
}
