import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Indicator } from './indicator-card.model';

@Component({
  selector: 'milestone-indicator-card',
  templateUrl: './indicator-card.component.html',
  styleUrls: ['./indicator-card.component.scss'],
})
export class IndicatorCardComponent {
  @Input() indicator!: Indicator;

  // Create an EventEmitter to emit the clicked title to the parent
  @Output() indicatorClicked: EventEmitter<string> = new EventEmitter<string>();

  // Method to handle click and emit the title to the parent
  onClick(): void {
    this.indicatorClicked.emit(this.indicator.title);
  }
}
