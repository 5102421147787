import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';

@Pipe({
  name: 'formatMediumDate',
})
export class FormatMediumDatePipe implements PipeTransform {
  transform(val: any): string {
    if (val) {
      val = dayjs.unix(val);
      const parsedDate = dayjs(val, 'MM-DD-YYYY');
      return parsedDate.format('D MMM YYYY');
    }
    return '';
  }
}
