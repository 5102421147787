import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfigService } from './../../../core/config/application-config.service';
import { Pagination } from './../../../core/request/request.model';
import { RequestSearch } from './../../..//core/specification/requestSearch';
import { OrderDetailPagenationsVM } from '../models/order-detail-vm.model';
import { createRequestOption } from './../../..//core/request/request-util';
import { AppConstant } from './../../../shared/app-constant';
import { IndicatorOrderDetailsVM } from '../models/indicator-shipment.model';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailsService {

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationConfigService
  ) { }

  query( pagination: Pagination, filters?: RequestSearch | null): Observable<OrderDetailPagenationsVM> {
    const paramsQuery = createRequestOption(pagination);
    return this.http
      .post<OrderDetailPagenationsVM>(
        this.applicationService.getEndpointFor(`/orderDetail/query`),
        filters,
        {headers: AppConstant.httpOptions.headers, params:paramsQuery}
      );
  }

  getStatisticCount(idOrder: string): Observable<IndicatorOrderDetailsVM>{
    return this.http.get<IndicatorOrderDetailsVM>(
      this.applicationService.getEndpointFor(`/orderDetail/count-Shipment/${idOrder}`),
       {headers: AppConstant.httpOptions.headers}
      );
  } 
}
