// how i can drow polygon on map for this  points
// (33.202971874967325, -8.415871713288645), (33.17941252710792, -8.465310189851145), (33.16102034092934, -8.40351209414802)
import { Component, OnInit } from '@angular/core';
import { PolygonService } from './draw-polygon.service';

@Component({
  selector: 'milestone-draw-polygon',
  templateUrl: './draw-polygon.component.html',
  styleUrls: ['./draw-polygon.component.scss'],
})
export class DrawPolygonComponent implements OnInit {
  map!: google.maps.Map;
  polygon!: google.maps.Polygon;
  paths: google.maps.LatLngLiteral[] = [];

  constructor(private polygonService: PolygonService) {}

  ngOnInit(): void {
    this.initMap();
  }

  initMap(): void {
    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center: { lat: 31.7917, lng: -7.0926 }, // Center the map at the desired location
        zoom: 7,
      }
    );

    this.polygon = new google.maps.Polygon({
      map: this.map,
      paths: this.paths,
      strokeColor: '#000000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#000000',
      fillOpacity: 0.35,
      editable: true,
    });

    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        this.addPoint(event.latLng);
      }
    });

    this.initFullscreenToggle();
  }

  addPoint(latLng: google.maps.LatLng): void {
    this.paths.push(latLng.toJSON());
    this.polygon.setPath(this.paths);
  }

  removePoint(): void {
    if (this.paths.length > 0) {
      this.paths.pop();
      this.polygon.setPath(this.paths);
    }
  }

  clearPolygon(): void {
    this.paths = [];
    this.polygon.setPath(this.paths);
  }

  changePolygonColor(event: Event): void {
    const input = event.target as HTMLInputElement;

    this.polygon.setOptions({
      strokeColor: input.value,
      fillColor: input.value,
    });
  }

  savePolygon(): void {
    const points = this.paths
      .map((point) => `(${point.lat}, ${point.lng})`)
      .join(', ');
   
      console.log(`Polygon points: ${points}`);
    // Pass the points to the service
    this.polygonService.sendPolygon(points);
  }

  addPointFromControl(): void {
    const center = this.map.getCenter();
    if (center) {
      const newPoint: google.maps.LatLngLiteral = {
        lat: center.lat() + (Math.random() - 0.5) * 0.01,
        lng: center.lng() + (Math.random() - 0.5) * 0.01,
      };

      this.addPoint(new google.maps.LatLng(newPoint));
    }
  }

  initFullscreenToggle(): void {
    const mapContainer = document.getElementById('map-container');
    const mapElement = document.getElementById('map');
    if (mapContainer && mapElement) {
      mapElement.addEventListener('dblclick', () => {
        if (!document.fullscreenElement) {
          mapContainer.classList.add('fullscreen-map');
          mapContainer.requestFullscreen();
        } else {
          document.exitFullscreen().then(() => {
            mapContainer.classList.remove('fullscreen-map');
          });
        }
      });
    }
  }
}
