import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstant } from '../app-constant';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteGoogleService {
  private apiKey = AppConstant.apiGoogleKey;
  private apiUrl ='https://maps.googleapis.com/maps/api/place/autocomplete/json';

  constructor(private http: HttpClient) {}

  getPlacePredictions(input: string): Observable<any> {
    const url = `${this.apiUrl}?input=${input}&key=${this.apiKey}`;
    return this.http.get(url);
  }
}
