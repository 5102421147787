import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';

@Pipe({
  name: 'formatMediumDatetime',
})
// https://day.js.org/docs/en/display/format
export class FormatMediumDatetimePipe implements PipeTransform {
  transform(val: any): string {
    if (val) {
      val = dayjs.unix(val);
      const parsedDate = dayjs(val, 'MM-DD-YYYY HH:mm:ss');
      return parsedDate.format('DD-MM-YYYY HH:mm:ss');
    }

    return '';
  }
}
