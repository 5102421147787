<div class="col" (click)="onClick()" style="cursor: pointer;" >
    <div class="card shadow-none">
      <div class="card-body p-1">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between gap-3"
        >
          <div>
            <p class="fw-medium text-primary-light mb-1">{{ indicator.title }}</p>
            <div class="items-icon">
              <div
                class="w-40-px h-30-px  d-flex justify-content-center align-items-center mr-16"
              >
                <iconify-icon
                  [icon]="indicator.icon"
                  class="{{indicator.colorIcon}} text-2xl mb-0 mx-16"
                ></iconify-icon>
              </div>
              <h6 class="mb-2 mx-10">{{ indicator.totalCount }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-bottom border-{{indicator.colorIcon.split('text-')[1]}}" style="width: 92%;margin:0px auto"></div>
  </div>
  