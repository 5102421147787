import { NgModule } from '@angular/core';

// import ngx-translate and the http loader
import { HttpClient } from '@angular/common/http';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import {
  missingTranslationHandler,
  translatePartialLoader,
} from '../core/config/translation.config';
import { LanguageService } from './language.service';
@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
  ],
  providers: [LanguageService],
  declarations: [],
})
export class TranslationModule {}
