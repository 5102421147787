import { getLocaleDirection } from '@angular/common';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(protected sessionStorageService: SessionStorageService) {}

  isRtl(): boolean {
    const locale = this.sessionStorageService.retrieve('locale');
    return getLocaleDirection(locale ?? 'fr') === 'rtl';
  }
}
