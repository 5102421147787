<div class="dropdown">
  <button type="button" class="dropbtn" (click)="toggleDropdown()">
    {{ selectedValue || placeholder }}
  </button>
  <div class="dropdown-content" [ngClass]="{ show: showDropdown }">
    <input type="text" placeholder="Search.." (input)="filterItems($event)" />
    <a *ngFor="let item of filteredItems" (click)="selectItem(item)">
      {{ item[displayField] }}
    </a>
  </div>
</div>
