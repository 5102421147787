<div class="dashboard-main-body">
  <!-- Start card list indcators -->

  <div class="row-indicator">
    <milestone-indicator-card
      *ngFor="let indicator of indicators"
      [indicator]="indicator"
    ></milestone-indicator-card>
  </div>
  <!-- End card list indcators -->

  <div class="row gy-4 mt-1">
    <!-- Start card list files -->
    <div class="col-xxl-9 col-xl-12">
      <div class="card h-100">
        <div class="card-body p-24">
          <div
            class="d-flex flex-wrap align-items-center gap-1 justify-content-between mb-16"
          >
            <span
              class="text-sm fw-semibold py-6 text-neutral-500 line-height-1 ms-12"
              >Résumé des opérations en cours</span
            >

            <form class="search-table">
              <input
                type="text"
                name="search"
                placeholder="Search ...."
                (input)="onGlobalSearch($event)"
              />
              <iconify-icon
                icon="ion:search-outline"
                class="icon"
              ></iconify-icon>
            </form>
          </div>

          <div
            class="d-flex flex-wrap align-items-center gap-1 justify-content-between mb-16"
          >
            <ul
              class="nav border-gradient-tab nav-pills mb-0"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link d-flex align-items-center active"
                  id="pills-to-do-list-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-to-do-list"
                  type="button"
                  role="tab"
                  aria-controls="pills-to-do-list"
                  aria-selected="true"
                >
                  Ordre de Ramassage
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link d-flex align-items-center"
                  id="pills-recent-leads-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-recent-leads"
                  type="button"
                  role="tab"
                  aria-controls="pills-recent-leads"
                  aria-selected="false"
                  tabindex="-1"
                >
                  Ordre de livraison
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link d-flex align-items-center"
                  id="pills-bac-transport-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-bac-transport"
                  type="button"
                  role="tab"
                  aria-controls="pills-bac-transport"
                  aria-selected="false"
                  tabindex="-1"
                >
                  Bac de transport
                </button>
              </li>
            </ul>
          </div>

          <div class="tab-content" id="pills-tabContent">
            <!-- Ordre de Ramassage Table -->
            <div
              class="tab-pane fade show active"
              id="pills-to-do-list"
              role="tabpanel"
              aria-labelledby="pills-to-do-list-tab"
              tabindex="0"
            >
              <div class="table-responsive scroll-sm">
                <table class="table bordered-table sm-table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Type de Ramassage</th>
                      <th scope="col">Référence de l’Ordre</th>
                      <th scope="col" class="text-center">Total colis</th>
                      <th scope="col" class="text-center">Opérateur</th>
                      <th scope="col" class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of filteredPickupOrders">
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <h6 class="text-md mb-0 fw-medium">
                              {{ order.date }}
                            </h6>
                            <span
                              class="text-sm text-secondary-light fw-medium"
                              >{{ order.time }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>{{ order.type }}</td>
                      <td>{{ order.reference }}</td>
                      <td class="text-center">
                        <span
                          class="bg-black text-white px-10 py-1 rounded-pill fw-medium text-sm"
                        >
                          Colis {{ order.totalColis }}
                        </span>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <h6 class="text-md mb-0 fw-medium">
                              {{ order.operatorName }}
                            </h6>
                            <span
                              class="text-sm text-secondary-light fw-medium"
                              >{{ order.operatorPhone }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          [ngClass]="order.statusClass"
                          class="fw-medium text-sm"
                          >{{ order.status }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Ordre de livraison Table -->
            <div
              class="tab-pane fade"
              id="pills-recent-leads"
              role="tabpanel"
              aria-labelledby="pills-recent-leads-tab"
              tabindex="0"
            >
              <div class="table-responsive scroll-sm">
                <table class="table bordered-table sm-table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Type de Livraison</th>
                      <th scope="col">Référence de l’Ordre</th>
                      <th scope="col" class="text-center">Total colis</th>
                      <th scope="col" class="text-center">Opérateur</th>
                      <th scope="col" class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of filteredDeliveryOrders">
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <h6 class="text-md mb-0 fw-medium">
                              {{ order.date }}
                            </h6>
                            <span
                              class="text-sm text-secondary-light fw-medium"
                              >{{ order.time }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>{{ order.type }}</td>
                      <td>{{ order.reference }}</td>
                      <td class="text-center">
                        <span
                          class="bg-black text-white px-10 py-1 rounded-pill fw-medium text-sm"
                        >
                          Colis {{ order.totalColis }}
                        </span>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <h6 class="text-md mb-0 fw-medium">
                              {{ order.operatorName }}
                            </h6>
                            <span
                              class="text-sm text-secondary-light fw-medium"
                              >{{ order.operatorPhone }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          [ngClass]="order.statusClass"
                          class="fw-medium text-sm"
                          >{{ order.status }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Bac de transport Table -->
            <div
              class="tab-pane fade"
              id="pills-bac-transport"
              role="tabpanel"
              aria-labelledby="pills-bac-transport-tab"
              tabindex="0"
            >
              <div class="table-responsive scroll-sm">
                <table class="table bordered-table sm-table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Type de Transport</th>
                      <th scope="col">Référence de l’Ordre</th>
                      <th scope="col" class="text-center">Total colis</th>
                      <th scope="col" class="text-center">Opérateur</th>
                      <th scope="col" class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of filteredTransportBacOrders">
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <h6 class="text-md mb-0 fw-medium">
                              {{ order.date }}
                            </h6>
                            <span
                              class="text-sm text-secondary-light fw-medium"
                              >{{ order.time }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>{{ order.type }}</td>
                      <td>{{ order.reference }}</td>
                      <td class="text-center">
                        <span
                          class="bg-black text-white px-10 py-1 rounded-pill fw-medium text-sm"
                        >
                          Colis {{ order.totalColis }}
                        </span>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <h6 class="text-md mb-0 fw-medium">
                              {{ order.operatorName }}
                            </h6>
                            <span
                              class="text-sm text-secondary-light fw-medium"
                              >{{ order.operatorPhone }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          [ngClass]="order.statusClass"
                          class="fw-medium text-sm"
                          >{{ order.status }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End card list files -->

    <!-- Start card list Operators -->
    <div class="col-xxl-3 col-xl-12">
      <div class="card h-100">
        <div class="card-body">
          <div
            class="d-flex align-items-center flex-wrap gap-2 justify-content-between"
          >
            <h6 class="mb-22 py-16 fw-bold text-lg mb-0">Opérateurs</h6>
            <a
              href="javascript:void(0)"
              class="text-primary-600 hover-text-primary d-flex align-items-center gap-1"
            >
              <iconify-icon icon="system-uicons:menu-vertical"></iconify-icon>
            </a>
          </div>

          <form class="search-table">
            <input
              type="text"
              name="search"
              placeholder="Search ...."
              (input)="onGlobalSearchOperator($event)"
            />
            <iconify-icon icon="ion:search-outline" class="icon"></iconify-icon>
          </form>

          <div class="mt-32">
            <div
              class="d-flex align-items-center justify-content-between gap-3 mb-24"
            >
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h6 class="text-md mb-0 fw-medium">Opérateur</h6>
                </div>
              </div>
              <span class="text-primary-light text-md fw-medium">Ordre</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between gap-3 mb-24"
            >
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h6 class="text-md mb-0 fw-medium">Wade Warren</h6>
                </div>
              </div>
              <span class="text-primary-light text-md fw-medium">20</span>
            </div>

            <div
              class="d-flex align-items-center justify-content-between gap-3 mb-24"
            >
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h6 class="text-md mb-0 fw-medium">Albert Flores</h6>
                </div>
              </div>
              <span class="text-primary-light text-md fw-medium">30</span>
            </div>

            <div
              class="d-flex align-items-center justify-content-between gap-3 mb-24"
            >
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h6 class="text-md mb-0 fw-medium">Bessie Cooper</h6>
                </div>
              </div>
              <span class="text-primary-light text-md fw-medium">40</span>
            </div>

            <div
              class="d-flex align-items-center justify-content-between gap-3 mb-24"
            >
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h6 class="text-md mb-0 fw-medium">Arlene McCoy</h6>
                </div>
              </div>
              <span class="text-primary-light text-md fw-medium">10</span>
            </div>

            <div
              class="d-flex align-items-center justify-content-between gap-3"
            >
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h6 class="text-md mb-0 fw-medium">Arlene McCoy</h6>
                </div>
              </div>
              <span class="text-primary-light text-md fw-medium">10</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End card list Operators -->
  </div>
</div>
