<div class="layout-container" [ngClass]="{ 'sidebar-hidden': !sidebarVisible }">
  <milestone-side-bare *ngIf="sidebarVisible"></milestone-side-bare>
  <div class="main-content">
    <milestone-header></milestone-header>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
    <footer class="d-footer">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto">
          <p class="mb-0">© 2024 Milstone. All Rights Reserved.</p>
        </div>
        <div class="col-auto">
          <p class="mb-0">
            Made by <span class="text-primary-600">Ceos Technology</span>
          </p>
        </div>
      </div>
    </footer>
  </div>
</div>
