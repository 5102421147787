import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { CreatePermissionModuleVM } from '../models/create_permission_module_vm';
import { PermissionModulePagenationsVM } from '../models/permission_module_vm';

@Injectable({
  providedIn: 'root',
})
export class PermissionModuleService {
  constructor(
    private http: HttpClient,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createPermissionModule(
    permissionModule: CreatePermissionModuleVM
  ): Observable<void> {
    const data = {
      modules: permissionModule.name,
      description: permissionModule.description,
    };
    return this.http
      .post<void>(
        this.applicationConfigService.getEndpointFor('/admin/modules'),
        data,
        AppConstant.httpOptions
      );
  }

  getPermissionModules(): Observable<PermissionModulePagenationsVM> {
    return this.http
      .get<PermissionModulePagenationsVM>(
        this.applicationConfigService.getEndpointFor('/modules/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
