import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, map } from 'rxjs';
import { AppConstant } from 'src/app/shared/app-constant';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { IOperatorPosition } from '../model/operator-position.model';

export type EntityResponseType = IOperatorPosition;
export type EntityArrayResponseType = IOperatorPosition[];

@Injectable({
  providedIn: 'root',
})
export class LocationPositionService {
    private base_url =""
    constructor(
        private applicationConfigService: ApplicationConfigService,
         private http : HttpClient
         ) {
        this.base_url= this.applicationConfigService.getEndpointFor('');
    }
    
    getAllOperatorsPositions(): Observable<EntityArrayResponseType> {
        return this.http.get<EntityArrayResponseType>(`${this.base_url}/operatorPositions/all-position`, AppConstant.httpOptions)
        .pipe(map((resp: EntityArrayResponseType) => this.convertDateArrayFromServer(resp)) )
    }

    getLastPositionOfUserByUserId(userId: number): Observable<EntityResponseType>{
        return this.http.get<EntityResponseType>(`${this.base_url}/operatorPositions/last-position/${userId}`, AppConstant.httpOptions)
        .pipe(map((resp: EntityResponseType) => this.convertDateFromServer(resp)) )
    }

    savePosition(operatorPosition : IOperatorPosition): Observable<any>{
     return  this.http.post(`${this.base_url}/operatorPositions/save-new-operatorPosition-then-trackingPosition`,operatorPosition, AppConstant.httpOptions);
     }

    protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
          res.createdDate = res.createdDate ? dayjs(res.createdDate) : undefined;
          res.lastModifiedDate = res.lastModifiedDate ? dayjs(res.lastModifiedDate) : undefined;
        
        return res;
      }

    protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {

          res.forEach((data: IOperatorPosition) => {
            data.createdDate = data.createdDate ? dayjs(data.createdDate) : undefined;
            data.lastModifiedDate = data.lastModifiedDate ? dayjs(data.lastModifiedDate) : undefined;
          });
        return res;
    }
    
}
