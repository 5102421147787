<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="name_phone">
    <div class="form-group">
      <label class="label" for="name">Name</label>
      <input
        id="name"
        formControlName="name"
        type="text"
        class="form-control"
        placeholder="Full Name"
      />
    </div>
    <div class="form-group">
      <label class="label" for="phone">Phone</label>
      <input
        id="phone"
        formControlName="phone"
        type="text"
        class="form-control"
        placeholder="Phone"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="label" for="description">Description</label>
    <textarea
      id="description"
      formControlName="description"
      class="form-control"
      placeholder="Description"
    ></textarea>
  </div>
  <div class="form-check">
    <input
      id="confirmation"
      formControlName="confirmation"
      type="checkbox"
      class="form-check-input"
    />
    <label for="confirmation" class="form-check-label">Yes, confirmation</label>
  </div>
  <div class="form-group mt-3">
    <button type="submit" class="btn btn-success">Create</button>
    <button type="button" class="btn btn-outline-primary" (click)="cancel()">
      Cancel
    </button>
  </div>
</form>
