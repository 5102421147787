import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PermissionModuleVM } from '../../permissions-module/models/permission_module_vm';
import { CreatePermissionFunctionVM } from '../models/create_permission_functions_vm';
import { PermissionModuleService } from '../../permissions-module/services/permissions-module.service';
import { PermissionFunctionsService } from '../services/permissions-functions.service';

@Component({
  selector: 'milestone-create-permission-functions-form',
  templateUrl: './create-permission-functions-form.component.html',
  styleUrls: ['./create-permission-functions-form.component.scss'],
})
export class CreatePermissionFunctionsFormComponent implements OnInit {
  userForm: FormGroup;
  createFunctionError = false;
  errorFunction = '';
  loading = false;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<void>();

  permissionModules: PermissionModuleVM[] = [];

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private permissionModuleService: PermissionModuleService,
    private permissionFunctionsService: PermissionFunctionsService
  ) {
    this.userForm = this.fb.group({
      description: ['', Validators.required],
      module: ['', Validators.required],
      functions: ['', Validators.required],
      confirmation: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadPermissionsModules();
  }

  loadPermissionsModules(): void {
    this.permissionModuleService.getPermissionModules().subscribe(
      (response) => {
        this.permissionModules = response.content;
      },
      () => {
        this.notification.showMessageError('Error loading Permission Modules');
      }
    );
  }

  onSubmit(): void {
    if (this.userForm.valid && this.userForm.value.confirmation) {
      this.loading = true;
      const newPermissionFunctions = new CreatePermissionFunctionVM(
        this.userForm.value.functions,
        this.userForm.value.module,
        this.userForm.value.description
      );

      this.permissionFunctionsService
        .createPermissionFunctions(newPermissionFunctions)
        .subscribe(
          () => {
            this.notification.showMessageSuccess(
              'PermissionFunctions created successfully'
            );
            this.loading = false;
            this.formSubmit.emit(this.userForm.value);
          },
          (error) => {
            this.createFunctionError = true;
            this.errorFunction = error.error.message;
            this.loading = false;
            this.notification.showMessageError(
              'Error creating PermissionFunctions'
            );
          }
        );
    } else {
      this.notification.showMessageError('Please fill in all required fields');
    }
  }

  selectModule(event: PermissionModuleVM): void {
    this.userForm.patchValue({ module: event.modules });
  }

  cancel(): void {
    this.formCancel.emit();
  }
}
