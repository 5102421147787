import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class I18nTranslateService {
  constructor(private translate: TranslateService) {}

  async translateText(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.translate.get(key).subscribe({
        next: (translated) => resolve(translated),
        error: (error) => reject(error),
      });
    });
  }
}
