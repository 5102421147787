import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { UserTypesService } from './user-types.service';
import { IUserTypeVM } from './models/user-type-vm.model';

@Component({
  selector: 'milestone-user-types',
  templateUrl: './user-types.component.html',
  styleUrls: ['./user-types.component.scss'],
})
export class UserTypesComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'User Type', field: 'userTypes' },
    { header: 'Description', field: 'description' },
    { header: 'Created by', field: 'createdBy' },
    { header: 'Created date', field: 'createdDate' },
    { header: 'Last modified by', field: 'lastModifiedBy' },
    { header: 'Last modified date', field: 'lastModifiedDate' },
  ];

  usersTypes: IUserTypeVM[] = [];

  dropdownFilters = [];

  constructor(
    private userTypesService: UserTypesService,
    private router: Router,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadZones();
  }

  loadZones(): void {
    this.userTypesService.getUserTypes().subscribe(
      (response) => {
        this.usersTypes = response.content;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      () => {
        this.notification.showMessageError('Error loading usersTypes');
      }
    );
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }
  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }
}
