// /api
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { CreateCodeTrackingVM } from './../models/create-code-trackin-vm.model';
import { CodeTrackingPagenationsVM, ICodeTrackingVM } from '../models/code-tracking-vm.model';

@Injectable({
  providedIn: 'root',
})
export class CodeTrackingService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createCodeTracking(codeTracking: CreateCodeTrackingVM): Observable<void> {
    const data = {
      codesTracking: codeTracking.codesTracking,
      description: codeTracking.description,
    };
    return this.http
      .post<void>(
        this.applicationConfigService.getEndpointFor('/dev/codeTracking'),
        data,
        AppConstant.httpOptions
      );
  }

  getCodeTrakings(): Observable<CodeTrackingPagenationsVM> {
    return this.http
      .get<CodeTrackingPagenationsVM>(
        this.applicationConfigService.getEndpointFor('/dev/codeTracking'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }

  getAllCodeTrakings(): Observable<ICodeTrackingVM[]> {
    return this.http
      .get<ICodeTrackingVM[]>(
        this.applicationConfigService.getEndpointFor('/codeTracking/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }

  getAllCodeTrakingsForWeb(): Observable<ICodeTrackingVM[]> {
    return this.http
      .get<ICodeTrackingVM[]>(
        this.applicationConfigService.getEndpointFor('/codeTracking/all/web'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
