<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div
    class="alert alert-danger"
    *ngIf="createPermissionModuleError"
    data-cy="loginError"
  >
    <strong>Create Module !</strong> {{ errorPermssionModule }}
  </div>

  <div class="name_phone">
    <div class="form-group">
      <label class="label" for="name">Module name</label>
      <input
        id="name"
        formControlName="name"
        type="text"
        class="form-control"
        placeholder="USERS ..."
      />
    </div>
  </div>
  <div class="form-group">
    <label class="label" for="description">Description</label>
    <textarea
      id="description"
      formControlName="description"
      class="form-control"
      placeholder="Description"
    ></textarea>
  </div>
  <div class="form-check">
    <input
      id="confirmation"
      formControlName="confirmation"
      type="checkbox"
      class="form-check-input"
    />
    <label for="confirmation" class="form-check-label">Yes, confirmation</label>
  </div>
  <div class="form-group mt-3">
    <button type="submit" class="btn-action-modal">
      <ng-container *ngIf="!loading; else spinner">
        <span> Create Module</span>
      </ng-container>
      <ng-template #spinner>
        <div class="spinner-border text-light" role="status"></div>
      </ng-template>
    </button>

    <!-- <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button> -->
  </div>
</form>
