import { Component } from '@angular/core';

@Component({
  selector: 'milestone-type-order',
  templateUrl: './type-order.component.html',
  styleUrls: ['./type-order.component.scss']
})
export class TypeOrderComponent {

}
