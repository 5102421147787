import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { CreateCityVM } from '../models/create-city-vm.model';
import { CityPagenationsVM, CityVM } from '../models/city-vm.model';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createCity(city: CreateCityVM): Observable<void> {
    const data = {
      idCity: city.idCity,
      cityName: city.cityName,
      agency: { idAgency: city.agency },
      zone: { zone: city.zone },
    };
    return this.http
      .post<void>(
        this.applicationConfigService.getEndpointFor('/admin/cities'),
        data,
        AppConstant.httpOptions
      );
  }

  getCities(): Observable<CityPagenationsVM> {
    return this.http
      .get<CityPagenationsVM>(
        this.applicationConfigService.getEndpointFor('/cities/query'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }

  getAllCities(): Observable<CityVM[]>{
    return this.http
      .get<CityVM[]>(
        this.applicationConfigService.getEndpointFor('/cities/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
