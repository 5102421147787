<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div
    class="alert alert-danger"
    *ngIf="createAgencyError"
    data-cy="loginError"
  >
    <strong>Create Agency !</strong> {{ errorAgency }}
  </div>

  <div class="form-group">
    <label class="label" for="agecny Id">Agency Id</label>
    <input
      id="agecny Id"
      formControlName="agecnyId"
      type="text"
      class="form-control"
      placeholder="Agency Id"
    />
  </div>

  <div class="form-group">
    <label class="label" for="agencyName">Agency name</label>
    <input
      id="agencyName"
      formControlName="agencyName"
      type="text"
      class="form-control"
      placeholder="Agence casablanca"
    />
  </div>

  <div class="form-group">
    <label class="label" for="addressAgency">Agency address</label>
    <input
      id="addressAgency"
      formControlName="addressAgency"
      type="text"
      class="form-control"
      placeholder="Borgoune casablanca"
    />
  </div>

  <div class="form-group">
    <label class="label" for="position">Agency position</label>
    <input
      id="position"
      formControlName="position"
      type="text"
      class="form-control"
      placeholder="32.000 ,-4.3434"
    />
  </div>

  <div class="form-group">
    <label class="label" for="description">Description</label>
    <textarea
      id="description"
      formControlName="description"
      class="form-control"
      placeholder="Description"
    ></textarea>
  </div>
  <div class="form-check">
    <input
      id="confirmation"
      formControlName="confirmation"
      type="checkbox"
      class="form-check-input"
    />
    <label for="confirmation" class="form-check-label">Yes, confirmation</label>
  </div>
  <div class="form-group mt-3">
    <button type="submit" class="btn-action-modal">
      <ng-container *ngIf="!loading; else spinner">
        <span> Create Agency</span>
      </ng-container>
      <ng-template #spinner>
        <div class="spinner-border text-light" role="status"></div>
      </ng-template>
    </button>
    <!-- <button type="button" class="btn-outlined" (click)="cancel()">Cancel</button> -->
  </div>
</form>
