
<milestone-custom-range-panel></milestone-custom-range-panel>
<div class="example-header">
  <!-- "previous" buttons -->
  <button
    mat-icon-button
    class="example-double-arrow"
    (click)="previousClicked('year')"
  >
  </button>
  <button mat-icon-button (click)="previousClicked('month')">
    <i _ngcontent-ng-c1766591684="" class="material-icons" >keyboard_arrow_left</i>
  </button>

  <!-- center label -->
  <span class="example-header-label">{{ periodLabel }}</span>

  <!-- "next" buttons -->
  <button mat-icon-button (click)="nextClicked('month')">
    <i _ngcontent-ng-c1766591684="" class="material-icons" >keyboard_arrow_right</i>
  </button>
  <button
    mat-icon-button
    class="example-double-arrow"
    (click)="nextClicked('year')"
  >
  </button>

</div>