import { Component, Input } from '@angular/core';
import { IOperatorVM } from 'src/app/modules/location/model/operator-indicators.model';

@Component({
  selector: 'milestone-operator-map-card',
  templateUrl: './operator-map-card.component.html',
  styleUrls: ['./operator-map-card.component.scss']
})
export class OperatorMapCardComponent {
  @Input() operator!: IOperatorVM;  // Expecting an operator input from the parent component

}


