import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { CityVM, ICityVM } from './models/city-vm.model';
import { CitiesService } from './services/cities.service';

@Component({
  selector: 'milestone-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss'],
})
export class CitiesComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'City ID', field: 'idCity' },
    { header: 'City', field: 'cityName' },
    { header: 'Agency', field: 'idAgency' },
    { header: 'zone', field: 'zone' },
    { header: 'Created by', field: 'createdBy' },
  ];

  cities: ICityVM[] = [];
  dropdownFilters = [];

  constructor(
    private citiesService: CitiesService,
    private router: Router,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadCities();
  }

  loadCities(): void {
    this.citiesService.getCities().subscribe(
      (response) => {
        this.cities = response.content;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      () => {
        this.notification.showMessageError('Error loading Cities');
      }
    );
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted Cities', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }

}
